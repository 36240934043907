import React from 'react';
import axios from 'axios';
const SERVER = 'https://www.tmibaord.shop';

const TestApiPage = () => {
  const api = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
  });
  // 마이보드 아이디 있음 $선택
  const getMyboard = () => {
    const a = '2704563292';
    axios
      .get(`${SERVER}/myboard?userId=${a}`)
      .then(function (response) {
        const resResult = response.data.result;
        console.log('마이보드 조회완료', resResult);
      })
      .catch(function (error) {
        console.log('마이보드 조회 실패', error);
        window.alert('마이보드 조회 실패');
      })
      .then(function () {});
  };

  // 마이보드 아이디 있음 절대
  const getMyboard2 = () => {
    axios
      .get(`https://www.tmibaord.shop/myboard?userId=2704563292`)
      .then(function (response) {
        const resResult = response.data.result;
        console.log('마이보드 조회완료', resResult);
      })
      .catch(function (error) {
        console.log('마이보드 조회 실패', error);
        window.alert('마이보드 조회 실패');
      })
      .then(function () {});
  };

  const getMyboard3 = () => {
    const a = '2704563292';
    axios
      .get(`/myboard?userId=${a}`)
      .then(function (response) {
        const resResult = response.data.result;
        console.log('마이보드 조회완료', resResult);
      })
      .catch(function (error) {
        console.log('마이보드 조회 실패', error);
        window.alert('마이보드 조회 실패');
      })
      .then(function () {});
  };

  const postMyboard = () => {
    api
      .post(
        `create/myboard`,
        {
          userId: 2704563292,
          emoji: '🐇',
          birthStatus: 'VISIBLE', // VISIBLE, INVISIBLE
          name: '테스트',
          mbti: '테스트',
          birth: '999966',
          myboardComments: '테스트',
          url1: '테스트',
          url1Type: '테스트',
          url2: '테스트',
          url2Type: '테스트',
          url3: '테스트',
          url3Type: '테스트',
          myboardStatus: 'Y',
        },
        {
          headers: {
            'X-ACCESS-TOKEN':
              'eyJ0eXBlIjoiand0IiwiYWxnIjoiSFMyNTYifQ.eyJ1c2VySWR4IjoyNzA0NTYzMjkyLCJpYXQiOjE2NzkyMDU5NTEsImV4cCI6MTY4MDY3NzE4MH0.pcJp9PkR-BFSN2wdk459KOBkE90Ri59UtkkxmA6hffY',
          },
        }
      )
      .then(function (response) {})
      .catch(function (error) {
        console.log('작성 에러', error);
        window.alert('마이보드 저장에 실패하였습니다.');
      })
      .then(function () {});
  };

  const postMyboard2 = () => {
    axios
      .post(
        `/create/myboard`,
        {
          userId: 2704563292,
          emoji: '🐇',
          birthStatus: 'VISIBLE', // VISIBLE, INVISIBLE
          name: '테스트',
          mbti: '테스트',
          birth: '999966',
          myboardComments: '테스트',
          url1: '테스트',
          url1Type: '테스트',
          url2: '테스트',
          url2Type: '테스트',
          url3: '테스트',
          url3Type: '테스트',
          myboardStatus: 'Y',
        },
        {
          headers: {
            'X-ACCESS-TOKEN':
              'eyJ0eXBlIjoiand0IiwiYWxnIjoiSFMyNTYifQ.eyJ1c2VySWR4IjoyNzA0NTYzMjkyLCJpYXQiOjE2NzkyMDU5NTEsImV4cCI6MTY4MDY3NzE4MH0.pcJp9PkR-BFSN2wdk459KOBkE90Ri59UtkkxmA6hffY',
          },
        }
      )
      .then(function (response) {})
      .catch(function (error) {
        console.log('작성 에러', error);
        window.alert('마이보드 저장에 실패하였습니다.');
      })
      .then(function () {});
  };

  // 마이보드 수정
  const putMyboard = () => {
    axios
      .put(
        `${SERVER}/myboard`,
        {
          emoji: '🏋️‍♀️',
          birthStatus: 'VISIBLE',
          name: '테스트',
          mbti: '테스트',
          birth: '555555',
          myboardComments: '테스트',
          url1: '테스트',
          url1Type: '테스트',
          url2: '테스트',
          url2Type: '테스트',
          url3: '테스트',
          url3Type: '테스트',
        },
        {
          headers: {
            'X-ACCESS-TOKEN': '2704563292',
          },
        }
      )
      .then(function (response) {
        console.log('response', response);
      })
      .catch(function (error) {
        console.log('수정 에러', error);
        window.alert('수정 실패하였습니다.');
      })
      .then(function () {});
  };

  const postTmiCard = () => {
    axios
      .post(
        `https://www.tmibaord.shop/tmicard/create`,
        {
          userId: 2704563292,
          cardEmoji: '👩‍🎨',
          cardColor: '#333333',
          title: '테스트',
          comments: '테스트',
          hashTagList: [],
        },
        {
          headers: {
            'X-ACCESS-TOKEN':
              'eyJ0eXBlIjoiand0IiwiYWxnIjoiSFMyNTYifQ.eyJ1c2VySWR4IjoyNzA0NTYzMjkyLCJpYXQiOjE2NzkyMDU5NTEsImV4cCI6MTY4MDY3NzE4MH0.pcJp9PkR-BFSN2wdk459KOBkE90Ri59UtkkxmA6hffY',
          },
        }
      )
      .then(function (response) {
        console.log('성공', response);
      })
      .catch(function (error) {
        console.log('작성 에러', error);
        window.alert('트미카드 작성에 실패하였습니다.');
      })
      .then(function () {});
  };

  const postTmiCard2 = () => {
    axios
      .post(
        `${SERVER}/tmicard/create`,
        {
          userId: 2704563292,
          cardEmoji: '👩‍🎨',
          cardColor: '#333333',
          title: '테스트',
          comments: '테스트',
          hashTagList: [],
        },
        {
          headers: {
            'X-ACCESS-TOKEN':
              'eyJ0eXBlIjoiand0IiwiYWxnIjoiSFMyNTYifQ.eyJ1c2VySWR4IjoyNzA0NTYzMjkyLCJpYXQiOjE2NzkyMDU5NTEsImV4cCI6MTY4MDY3NzE4MH0.pcJp9PkR-BFSN2wdk459KOBkE90Ri59UtkkxmA6hffY',
          },
        }
      )
      .then(function (response) {
        console.log('성공', response);
      })
      .catch(function (error) {
        console.log('작성 에러', error);
        window.alert('트미카드 작성에 실패하였습니다.');
      })
      .then(function () {});
  };

  const postTmiCard3 = () => {
    api
      .post(`tmicard/create`, {
        userId: 2704563292,
        cardEmoji: '👩‍🎨',
        cardColor: '#333333',
        title: '테스트',
        comments: '테스트',
        hashTagList: [],
      })
      .then(function (response) {
        console.log('성공', response);
      })
      .catch(function (error) {
        console.log('작성 에러', error);
        window.alert('트미카드 작성에 실패하였습니다.');
      })
      .then(function () {});
  };

  const postTmiCard4 = () => {
    api
      .post(
        `tmicard/create`,
        {
          userId: 2704563292,
          cardEmoji: '👩‍🎨',
          cardColor: '#333333',
          title: '테스트',
          comments: '테스트',
          hashTagList: [],
        },
        {
          headers: {
            'X-ACCESS-TOKEN':
              'eyJ0eXBlIjoiand0IiwiYWxnIjoiSFMyNTYifQ.eyJ1c2VySWR4IjoyNzA0NTYzMjkyLCJpYXQiOjE2NzkyMDU5NTEsImV4cCI6MTY4MDY3NzE4MH0.pcJp9PkR-BFSN2wdk459KOBkE90Ri59UtkkxmA6hffY',
          },
        }
      )
      .then(function (response) {
        console.log('성공', response);
      })
      .catch(function (error) {
        console.log('작성 에러', error);
        window.alert('트미카드 작성에 실패하였습니다.');
      })
      .then(function () {});
  };

  // 트미카드 조회
  const getTmiCard = () => {
    axios
      .get(`${SERVER}/tmicard/list/2704563292`)
      .then(function (response) {
        console.log('트미카드 조회 성공');
        const resResult = response.data.result;
        console.log(resResult);
      })
      .catch(function (error) {
        console.log('트미카드 조회 실패', error);
        window.alert('트미카드 조회 실패');
      })
      .then(function () {});
  };

  // const putTmiCard = () => {};
  // const deleteTmiCard = () => {};
  // const getRandomHashTag = () => {};
  // const postInteract = () => {};

  return (
    <>
      <h1>jwt, id 포함</h1>
      <h2>마이보드 관련</h2>
      <button onClick={getMyboard}>마이보드 조회 ($)</button>
      <button onClick={getMyboard2}>마이보드 조회</button>
      <button onClick={getMyboard3}>마이보드 조회</button>
      <button onClick={postMyboard}>api마이보드 작성</button>
      <button onClick={postMyboard2}>마이보드 작성($)</button>

      <button onClick={putMyboard}>마이보드 수정O</button>

      <h1>jwt, id 미포함</h1>
      <h2>트미카드 관련</h2>
      <button onClick={getTmiCard}>트미카드 조회</button>
      <button onClick={postTmiCard}>트미카드 작성</button>
      <button onClick={postTmiCard2}>트미카드 작성</button>
      <button onClick={postTmiCard3}>트미카드 작성(헤더없음)</button>
      <button onClick={postTmiCard4}>트미카드 작성(헤더넣음)</button>
      {/* <button onClick={putTmiCard}>트미카드 수정</button>
      <button onClick={deleteTmiCard}>트미카드 삭제</button>

      <h2> 관련</h2>
      <button onClick={getRandomHashTag}>랜덤 해시태그 요청</button>
      <button onClick={postInteract}>상호작용</button> */}
    </>
  );
};

export default TestApiPage;
