import styled from 'styled-components';
import '../assets/fonts/font.css';

export const EntireBox = styled.div`
  display: inline-flex;
  flex-wrap: wrap; // div 요소 크기를 벗어났을 때
  gap: 18px; // flex-wrap 사이
  box-sizing: border-box; /* 자식을 줄여라 */
  text-align: left;
`;

export const MiniBox = styled.div`
  box-sizing: border-box; /* 자식을 줄여라 */
  width: 170px;
  height: 170px;
  text-align: center;

  background: ${(props) => props.color};
  border-radius: 45px;
`;

export const ContentsBox = styled.div`
  box-sizing: border-box; /* 자식을 줄여라 */
  display: inline-block;
  margin-top: 30px;
  pointer-events: none; /* 전체 클릭 적용 */
`;

export const EmojiBox = styled.div`
  width: 60px;
  height: 60px;
  display: table;
`;

export const EmojiText = styled.span`
  font-weight: 400;
  font-size: 65px;
  line-height: 74px;
  text-align: center;
  vertical-align: middle;
`;

export const TitleTextBox = styled.div`
  margin-top: 15px;
`;

export const TitleText = styled.span`
  font-family: 'PretendardBold';
  font-style: normal;
  font-size: 19px;
  line-height: 23px;
  text-align: center;
  color: #000000;
`;

export const PlusButtonBox = styled.div`
  width: 170px;
  height: 170px;

  background: #000000;
  border-radius: 45px;
  text-align: center;
  display: flex;
`;

export const PlusImg = styled.img`
  width: 30px;
  height: 30px;
  margin: auto;
`;

export const HiddenPlusButtonBox = styled.div`
  width: 170px;
  height: 170px;
  visibility: hidden;
  border-radius: 45px;
  text-align: center;
  display: flex;
`;
