import { useMediaQuery } from 'react-responsive';

// const Desktop = ({
//   children,
// }: {
//   children: JSX.Element;
// }): JSX.Element | null => {
//   const isDesktop = useMediaQuery({ minWidth: 393 });
//   return isDesktop ? children : null;
// };

// const Tablet = ({
//   children,
// }: {
//   children: JSX.Element;
// }): JSX.Element | null => {
//   const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 1023 });
//   return isTablet ? children : null;
// };

const Mobile = ({
  children,
}: {
  children: JSX.Element;
}): JSX.Element | null => {
  const isMobile = useMediaQuery({ maxWidth: 2000 });
  return isMobile ? children : null;
};

// mobile이 아닐 때만 출력되는 컴포넌트
const Default = ({
  children,
}: {
  children: JSX.Element;
}): JSX.Element | null => {
  const isNotMobile = useMediaQuery({ minWidth: 1025 });
  return isNotMobile ? children : null;
};

// export { Desktop, Tablet, Mobile, Default };
export { Mobile, Default };
