import React from 'react';
import {
  EntireBox,
  MiniBox,
  EmojiBox,
  EmojiText,
  ContentsBox,
  TitleText,
  TitleTextBox,
  PlusButtonBox,
  PlusImg,
  HiddenPlusButtonBox,
} from './tmiCardMinis.styled';
import plusButtonWt from '../assets/images/plusButtonWt.png';
// import TmiCardMiniButton from '../components/TmiCardMiniButton';

interface TmiCardMinisProps {
  array: TmiCardAllProps[];
  addTmiCardFunction: any;
  userId?: number;
}

// 각 카드 한개에 대한 정보
export interface TmiCardAllProps {
  cardId: number;
  color: string;
  emoji: string;
  title: string;
  hashTag: string[];
  comments: string;
  onClick?: any;
  greatCount: number;
  heartCount: number;
  clapCount: number;
  selectedInteract: string;
  onClickCard?: any;
}
// tmiCardDataReal: 모든 트미카드데이터,onClickAddCard 카드 추가
const TmiCardMinis = ({
  array,
  addTmiCardFunction,
  userId,
}: TmiCardMinisProps) => {
  console.log(array);
  return (
    <EntireBox>
      {array.map((card: TmiCardAllProps) => (
        <MiniBox
          color={card.color}
          id={`${card.cardId}`}
          onClick={card.onClickCard}
          key={`${card.cardId}`}
        >
          <ContentsBox>
            <EmojiBox>
              <EmojiText>{card.emoji}</EmojiText>
            </EmojiBox>
            <TitleTextBox>
              <TitleText>{card.title}</TitleText>
            </TitleTextBox>
          </ContentsBox>
        </MiniBox>
      ))}
      {Number(localStorage.getItem('loginUserId')) === userId && (
        <PlusButtonBox onClick={addTmiCardFunction}>
          <PlusImg
            src={plusButtonWt}
            id='CardPlusBtn'
            onClick={(e) => e.stopPropagation()}
          />
        </PlusButtonBox>
      )}
      <HiddenPlusButtonBox></HiddenPlusButtonBox>
    </EntireBox>
  );
};

export default TmiCardMinis;
