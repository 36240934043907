import React from 'react';
import { EntireBox, HashTag, HashTagText } from './HashTagContentsWhite.styled';
interface HashTagProps {
  array: string[];
  onClick?: any;
}

// signin4 해시카드 사용
// TmiCardLarges 해시카드 사용
// AdminTmiCardLargeUpdate 해시카드 사용
const HashTagContentsWhite = ({ array, onClick }: HashTagProps) => {
  return (
    <EntireBox>
      {array.map((tag: string, index: number) => (
        <HashTag key={index} id={String(index)} onClick={onClick}>
          <HashTagText>{tag}</HashTagText>
        </HashTag>
      ))}
    </EntireBox>
  );
};

export default HashTagContentsWhite;
