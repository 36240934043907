import styled from 'styled-components';
import '../../../assets/fonts/font.css';

export const EntireBox = styled.div`
  // width: 393px; 제거
  display: inline-block;
`;

export const GreetingBox = styled.div`
  padding-top: 217px;
  text-align: center;
`;

export const Hello = styled.p`
  font-family: 'GmarketSansMedium';
  font-weight: 500;
  font-size: 18px;
  margin: 0px;
  text-align: center;
`;

// export const MainLogo = styled.div`
//   > img {
//     width: 250px;
//     height: 66px;
//     padding-bottom: 67px;
//   }
// `;

export const MainLogo = styled.img`
  width: 250px;
`;

export const ImgKakao = styled.img`
  width: 32px;
  height: 34px;
  padding-right: 2px;
`;

export const LoginText = styled.p`
  text-align: center;
  font-family: 'GmarketSansMedium';
  justify-content: center;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  color: #9e9e9e;
  padding-top: 27px;
`;
export const LoginWrap = styled.div`
  padding-top: 67px;
`;

export const KakaoButton = styled.button`
  width: 335px;
  height: 54px;
  background-color: #ffdc26;
  font-family: 'GmarketSansMedium';
  font-style: normal;
  font-size: 12px;
  line-height: 14px;
  border-radius: 15px;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  justify-content: center;
  border: none;
  color: #565656;
  display: flex;
  align-items: center;

  & + div > & {
    background-color: red;
  }

  & + & {
    margin-top: 19px;
  }
`;

// .button {
//   font-size: 13px;
//   letter-spacing: 0.3px;
//   /* font-weight: 500; */
//   /* box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1); */
//   /* transition: all 0.3s ease 0s; */
//   cursor: pointer;
//   outline: none;
// }
