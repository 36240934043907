import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  HeaderBox,
  TitleBox,
  Title1,
  Title2,
  ProfileBox,
  UrlBox,
  SnsBox,
  UrlText,
  SnsIconBox,
  SnsIcon,
  UrlInputs,
  EmojiBox,
  TextName,
  EntireBox,
  BtnBox,
  MyboardBox,
} from './signIn3.styled';
import { Default } from '../../../components/MediaQuery';
import TextBox from '../../../components/TextBox';
import ProfileCard from '../../../components/ProfileCard';
import ButtonSmall from '../../../components/ButtonSmall';
import logo_bondi from '../../../assets/images/logo_bondi.png';
import logo_brunch from '../../../assets/images/logo_brunch.png';
import logo_instagram from '../../../assets/images/logo_instagram.png';
import logo_kakaotalk from '../../../assets/images/logo_kakaotalk.png';
import logo_naverblog from '../../../assets/images/logo_naverblog.png';
import logo_tiktok from '../../../assets/images/logo_tiktok.png';
import logo_tistory from '../../../assets/images/logo_tistory.png';

export interface UserProfileInfo {
  userEmoji: string;
  inputName: string;
  inputMbti: string;
  inputBirth: string;
  inputPublicBirth: boolean;
  inputComment: string;
}

const INIT_UserProfileInfo = {
  userEmoji: '',
  inputName: '',
  inputMbti: '',
  inputBirth: '',
  inputPublicBirth: false,
  inputComment: '',
};

export interface SNSInfo {
  urlType: string;
  url: string;
}

export interface UserSignInProps {
  myboardData: UserProfileInfo;
  snsData: SNSInfo[];
}

const SignIn3 = () => {
  const navigate = useNavigate();
  const [arrSns, setArrSns] = useState<number>(0);
  const [storedProfile, setStoredProfile] =
    useState<UserProfileInfo>(INIT_UserProfileInfo);
  const [selectedSNSData, setSelectedSNSData] = useState<SNSInfo[]>([]);

  useEffect(() => {
    const localProfile = localStorage.getItem('MyboardData') ?? ''; // 마이보드 데이터

    if (localProfile === '') {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      // 초기화 마이보드 값
      setStoredProfile(INIT_UserProfileInfo);
    } else {
      // 앞에서 입력받은 마이보드 값
      setStoredProfile(JSON.parse(localProfile));
    }

    // console.log(storedProfile);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onClickSnsIcon = (event: any) => {
    const clickedUrlType = event.target.id;
    // console.log(`Clicked Type: ${clickedUrlType}`);
    // console.log(selectedSNSData);

    if (selectedSNSData.length < 3) {
      const cnt = arrSns + 1; // 개수
      // defalut 값
      if (clickedUrlType === 'instagram') {
      }

      const newSNSArray = {
        urlType: clickedUrlType,
        url: '',
      };

      setSelectedSNSData([...selectedSNSData, newSNSArray]);
      setArrSns(cnt);
    } else {
      window.alert('3개까지 추가 가능합니다.');
    }
  };

  // URL 값 변경 시
  const onChangeUrl = (event: any) => {
    const inputId = event.target.name; // 카카오톡, 틱톡 등
    const inputUrlValue = event.target.value; // url

    // id뒤에 숫자가, 배열의 index와 같다.
    selectedSNSData[inputId.substr(3, 4) - 1].url = inputUrlValue;

    setSelectedSNSData([...selectedSNSData]);
  };

  /* 삭제 */
  const onClickDeleteSnsIcon = (event: any) => {
    const selectedSnsIndex = event.target.id;

    // console.log(selectedSnsIndex);
    // 삭제하기 위해 눌렀으면,
    if (selectedSnsIndex !== '') {
      const confirm = window.confirm(
        `${
          selectedSNSData[parseInt(selectedSnsIndex) - 1].urlType
        } 정보를 삭제할까요?`
      );
      if (confirm) {
        const filteredSNSData = selectedSNSData.filter(
          (snsData, index) => index + 1 !== parseInt(selectedSnsIndex)
        );
        setSelectedSNSData(filteredSNSData);
      }
    }
  };

  /* 마이보드 완료 */
  const onClickFinish = () => {
    // console.log(selectedSNSData);
    const signInProps: UserSignInProps = {
      myboardData: storedProfile,
      snsData: selectedSNSData,
    };

    navigate('/signin4', { state: signInProps });
  };

  return (
    <>
      <EntireBox>
        <HeaderBox>
          <TitleBox>
            <Title1>
              좋아요!
              <br />
              <Title2>자기소개 영역은 이렇게 채워집니다.</Title2>
            </Title1>
          </TitleBox>
        </HeaderBox>
        {/* 위 : 글씨 / 아래 : 프로필 */}
        <ProfileBox>
          <EmojiBox>{storedProfile.userEmoji}</EmojiBox>
          <TextName>{storedProfile.inputName}</TextName>
          <MyboardBox>
            <ProfileCard
              intro={storedProfile.inputComment}
              birth={storedProfile.inputBirth}
              mbti={storedProfile.inputMbti}
              snsInfo={selectedSNSData}
              onClickSns={onClickDeleteSnsIcon}
            />
          </MyboardBox>
        </ProfileBox>
        <SnsBox>
          <UrlText>
            오른쪽 세 개의 아이콘 영역에 채워질 SNS 주소를 입력해주세요. (생략
            가능)
          </UrlText>
          <SnsIconBox>
            <SnsIcon
              src={logo_kakaotalk}
              alt=''
              onClick={onClickSnsIcon}
              id='kakaoTalk'
            />
            <SnsIcon
              src={logo_instagram}
              alt=''
              onClick={onClickSnsIcon}
              id='instagram'
            />
            <SnsIcon
              src={logo_tiktok}
              alt=''
              onClick={onClickSnsIcon}
              id='tiktok'
            />
            <SnsIcon
              src={logo_naverblog}
              alt=''
              onClick={onClickSnsIcon}
              id='naverblog'
            />
            <SnsIcon
              src={logo_tistory}
              alt=''
              onClick={onClickSnsIcon}
              id='tistory'
            />
            <SnsIcon
              src={logo_brunch}
              alt=''
              onClick={onClickSnsIcon}
              id='brunch'
            />
            <SnsIcon
              src={logo_bondi}
              alt=''
              onClick={onClickSnsIcon}
              id='bondi'
            />
          </SnsIconBox>
        </SnsBox>
        <UrlBox>
          <UrlInputs>
            {selectedSNSData.map((snsData: SNSInfo, index) => (
              <TextBox
                placeholder='URL'
                name={`url${index + 1}`}
                key={`url${index + 1}`}
                onChange={onChangeUrl}
                value={snsData.url}
              />
            ))}
          </UrlInputs>
        </UrlBox>
        <BtnBox>
          <ButtonSmall text='완료' onClick={onClickFinish} />
        </BtnBox>
      </EntireBox>
      <Default>
        <br></br>
      </Default>
    </>
  );
};

export default SignIn3;
