/**
 * 회원 트미보드 메인
 */
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { Default, Mobile } from '../../components/MediaQuery';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import TmiCardCarousel from '../../components/TmiCardCarousel';
import HashTagContents from '../../components/HashTagContentsBlack';
import TmiCardMinis, { TmiCardAllProps } from '../../components/TmiCardMinis';
import {
  EntireBox,
  MyboardBox,
  HashTagBox,
  TmiCardView,
  ProfileBox,
  TextName,
  EmojiBox,
  TmiCardMiniBox2,
  TmiCardLargeBox,
  SettingBtn,
} from './tmiBoard.styled';
import ProfileCard from '../../components/ProfileCard';
import { useNavigate } from 'react-router-dom';
import imgSetting from '../../assets/images/btn_setting.png';
import { MyboardInfo, SNSInfo } from '../propsData';

const initMyboardInfo: MyboardInfo = {
  boardId: 0,
  userId: Number(localStorage.getItem('loginUserId')) ?? 0,
  cardId: 0,
  emoji: '',
  name: '',
  mbti: '',
  birth: '',
  publicBirth: '',
  comment: '',
  snsInfo: [],
};

const TmiBoard = () => {
  const { userId } = useParams();
  const [randomHashTag, setRandomHashTag] = useState<string[]>([]); // 랜덤 해시 태그
  const [isMiniView, setIsMiniView] = useState<boolean>(true);
  const [tmiCardDataReal, setTmiCardDataReal] = useState<TmiCardAllProps[]>([]);
  const [myboardData, setMyboardData] = useState<MyboardInfo>(initMyboardInfo);
  const navigate = useNavigate();
  const api = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
  });

  useEffect(() => {
    console.log('userId', userId);
    if (!userId) {
      if (localStorage.getItem('loginUserId') === null) {
        navigate(`/`);
      } else {
        navigate(`/tmiboard/${localStorage.getItem('loginUserId')}`);
      }
    }
    // 마이보드 정보 API 요청
    getMyboard();
    // 랜덤 해시태그 API 요청
    getMyHashTag();
    // 트미카드 정보 API 요청
    getTmiCard();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onClickMiniCard = (event: any) => {
    setIsMiniView(!isMiniView);
  };

  /* 랜덤 해시태그 정보 */
  const getMyHashTag = async () => {
    api
      .get(
        `hashTag?userId=${
          userId ? userId : localStorage.getItem('loginUserId')
        }`
      )
      .then(function (response: { data: { result: any } }) {
        const resResult = response.data.result;

        setRandomHashTag(resResult);
      })
      .catch(function (error: any) {
        console.log('해시태그 조회 실패', error);
        window.alert('해시태그 조회 실패');
      })
      .then(function () {});
  };

  /* 트미카드 정보 */
  const getTmiCard = async () => {
    api
      .get(
        `tmicard/list/${userId ? userId : localStorage.getItem('loginUserId')}`,
        {
          headers: {
            'X-ACCESS-TOKEN': localStorage.getItem('loginJwt'),
          },
        }
      )
      .then(function (response: { data: { result: any } }) {
        const resResult = response.data.result;
        console.log('트미카드 조회', resResult);
        let arrTmiCardReal: TmiCardAllProps[] = [];
        if (resResult !== null) {
          for (let i = 0; i < resResult.length; i += 1) {
            const addData: TmiCardAllProps = {
              cardId: resResult[i].cardId,
              emoji: resResult[i].cardEmoji ? resResult[i].cardEmoji : '',
              color: resResult[i].cardColor ? resResult[i].cardColor : '',
              title: resResult[i].title === null ? '' : resResult[i].title,
              comments:
                resResult[i].comments === null ? '' : resResult[i].comments,
              hashTag:
                resResult[i].hashTagList === null ||
                resResult[i].hashTagList === undefined
                  ? []
                  : resResult[i].hashTagList,
              greatCount: resResult[i].greatCount,
              heartCount: resResult[i].heartCount,
              clapCount: resResult[i].clapCount,
              selectedInteract: resResult[i].selectedInteract,
              onClickCard: onClickMiniCard,
            };

            arrTmiCardReal = [...arrTmiCardReal, addData];
          }
          setTmiCardDataReal(arrTmiCardReal);
        } else {
          setTmiCardDataReal([]);
        }
      })
      .catch(function (error: any) {
        console.log('트미카드 조회 실패', error);
        window.alert('트미카드 조회 실패');
      })
      .then(function () {});
  };

  /* 마이보드 정보 */
  const getMyboard = async () => {
    console.log(userId);
    api
      .get(
        `myboard?userId=${
          userId ? userId : localStorage.getItem('loginUserId')
        }`
      )
      .then(function (response: { data: { result: any } }) {
        const resResult = response.data.result;

        // snsInfo 배열 생성
        let getSnsArr: SNSInfo[] = [];
        if (resResult.url1Type !== '') {
          const snsObj: SNSInfo = {
            urlType: resResult.url1Type,
            url: resResult.url1,
          };

          getSnsArr.push(snsObj);
        }
        if (resResult.url2Type !== '') {
          const snsObj: SNSInfo = {
            urlType: resResult.url2Type,
            url: resResult.url2,
          };

          getSnsArr.push(snsObj);
        }
        if (resResult.url3Type !== '') {
          const snsObj: SNSInfo = {
            urlType: resResult.url3Type,
            url: resResult.url3,
          };

          getSnsArr.push(snsObj);
        }

        const addData: MyboardInfo = {
          boardId: resResult.boardId,
          userId: resResult.userId,
          cardId: resResult.cardId,
          emoji: resResult.emoji,
          name: resResult.name,
          mbti: resResult.mbti,
          birth: resResult.birth,
          publicBirth: resResult.publicBirth,
          comment: resResult.myboardComments,
          snsInfo: getSnsArr,
        };

        setMyboardData(addData);
        console.log('마이보드 조회완료', resResult);
      })
      .catch(function (error: any) {
        console.log('마이보드 조회 실패', error);
        window.alert('마이보드 조회 실패');
      })
      .then(function () {});
  };

  const onClickUptMyBoard = () => {
    // 업데이트 화면으로 이동
    navigate(`/tmiboard/update`);
  };

  // /* 트미카드 미니카드 추가 */
  // const onClickAddCard = () => {
  //   const newTmiCard: TmiCardAllProps = {
  //     cardId: tmiCardDataReal.length,
  //     color: '#DBE5E3',
  //     emoji: '🍔',
  //     title: '주제',
  //     hashTag: [],
  //     comments: '',
  //     // onClick?: any;
  //     greatCount: 0,
  //     heartCount: 0,
  //     clapCount: 0,
  //     selectedInteract: 'N',
  //     // onClickCard?: any;
  //   };

  //   setTmiCardDataReal([...tmiCardDataReal, newTmiCard]);
  // };

  /* 트미카드 추가 */
  const onClickAddCard = () => {
    navigate(`/tmicard/update`);
  };

  /* 마이보드 View SNS 아이콘 클릭 시 이동 */
  const onClickViewSns = (event: any) => {
    const snsUrlIndex = event.target.id - 1;
    const snsUrl = myboardData.snsInfo[snsUrlIndex]?.url;

    if (snsUrl) {
      window.open(snsUrl);
    }
  };

  return (
    <div>
      <Mobile>
        <EntireBox>
          <Header />
          <MyboardBox>
            <ProfileBox>
              {/* 로그인유저 id와 보드 주인 ID가 같으면 설정아이콘 보임 */}
              {localStorage.getItem('loginUserId') ===
                String(myboardData.userId) && (
                <SettingBtn
                  src={imgSetting}
                  onClick={onClickUptMyBoard}
                ></SettingBtn>
              )}
              <EmojiBox>
                {myboardData.emoji}
                <TextName>{myboardData.name}</TextName>
              </EmojiBox>
              <ProfileCard
                intro={myboardData.comment}
                birth={myboardData.birth}
                mbti={myboardData.mbti}
                snsInfo={myboardData.snsInfo}
                onClickSns={onClickViewSns}
              />
            </ProfileBox>
          </MyboardBox>
          <HashTagBox>
            <HashTagContents array={randomHashTag}></HashTagContents>
          </HashTagBox>

          <TmiCardView>
            {/* 트미카드 모아보기 or 상세보기 */}
            {isMiniView ? (
              <>
                <TmiCardMiniBox2>
                  <TmiCardMinis
                    array={tmiCardDataReal}
                    addTmiCardFunction={onClickAddCard}
                    userId={myboardData.userId}
                  ></TmiCardMinis>
                </TmiCardMiniBox2>
              </>
            ) : (
              <TmiCardLargeBox>
                <TmiCardCarousel
                  array={tmiCardDataReal}
                  setArray={setTmiCardDataReal}
                ></TmiCardCarousel>
              </TmiCardLargeBox>
            )}
          </TmiCardView>
        </EntireBox>
      </Mobile>
      <Default>
        <br></br>
      </Default>
      <Footer />
    </div>
  );
};

export default TmiBoard;
