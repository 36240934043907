import React from 'react';
import {
  MainBox,
  BlueBox,
  GreenBox,
  RedBox,
  LeftBox,
  RightBox,
  MyProfile1,
  BlueImg,
  EntireBox,
} from './profileCard.styled';
import logo_bondi from '../assets/images/logo_bondi.png';
import logo_brunch from '../assets/images/logo_brunch.png';
import logo_instagram from '../assets/images/logo_instagram.png';
import logo_kakaotalk from '../assets/images/logo_kakaotalk.png';
import logo_naverblog from '../assets/images/logo_naverblog.png';
import logo_tiktok from '../assets/images/logo_tiktok.png';
import logo_tistory from '../assets/images/logo_tistory.png';
import { SNSInfo } from '../pages/propsData';
export interface MyboardInfo {
  intro: string;
  birth: string;
  mbti: string;
  snsInfo: SNSInfo[];
}

const ProfileCard = ({ snsInfo, intro, birth, mbti, onClickSns }: any) => {
  const getImageSrc = (urlType: string): string => {
    switch (urlType) {
      case 'bondi':
        return logo_bondi;
      case 'brunch':
        return logo_brunch;
      case 'instagram':
        return logo_instagram;
      case 'kakaoTalk':
        return logo_kakaotalk;
      case 'naverblog':
        return logo_naverblog;
      case 'tiktok':
        return logo_tiktok;
      case 'tistory':
        return logo_tistory;
      default:
        return '';
    }
  };

  return (
    <>
      <EntireBox>
        <MainBox>
          <MyProfile1>
            <LeftBox>
              <RedBox>
                {birth}
                <br />
                {mbti}
              </RedBox>
              <GreenBox>{intro}</GreenBox>
            </LeftBox>
            <RightBox>
              <BlueBox>
                <BlueImg
                  src={
                    snsInfo.length >= 1 ? getImageSrc(snsInfo[0].urlType) : ''
                  }
                  onClick={onClickSns}
                  id={snsInfo.length >= 1 ? '1' : ''}
                ></BlueImg>
              </BlueBox>
              <BlueBox>
                <BlueImg
                  src={
                    snsInfo.length >= 2 ? getImageSrc(snsInfo[1].urlType) : ''
                  }
                  onClick={onClickSns}
                  id={snsInfo.length >= 2 ? '2' : ''}
                  // id={snsInfo.length >= 2 ? snsInfo[1].urlType : ''}
                ></BlueImg>
              </BlueBox>
              <BlueBox>
                <BlueImg
                  src={
                    snsInfo.length >= 3 ? getImageSrc(snsInfo[2].urlType) : ''
                  }
                  onClick={onClickSns}
                  // id={snsInfo.length >= 3 ? snsInfo[2].urlType : ''}
                  id={snsInfo.length >= 3 ? '3' : ''}
                ></BlueImg>
              </BlueBox>
            </RightBox>
          </MyProfile1>
        </MainBox>
      </EntireBox>
    </>
  );
};

export default ProfileCard;
