import styled from 'styled-components';
import '../assets/fonts/font.css';

export const EntireBox = styled.div`
  display: inline-flex;
  flex-wrap: wrap; // div 요소 크기를 벗어났을 때
  gap: 8px; // flex-wrap 사이
  box-sizing: border-box; /* 자식을 줄여라 */
`;

export const HashTag = styled.div`
  box-sizing: border-box; /* 자식을 줄여라 */
  padding-left: 16px;
  padding-right: 16px;
  padding-top: 6px;
  padding-bottom: 6px;
  color: #000000;
  border-radius: 20px;
  border: 1px solid #000000;
  border-radius: 30px;
`;

export const HashTagText = styled.span`
  font-family: 'PretendardMedium';
  font-size: 11px;
  justify-content: center;
  align-items: center;
  display: flex;
  color: #000000;
  font-weight: 600;
  pointer-events: none; /* 전체 클릭 적용(모든 포인터 이벤트(클릭, 드래그, 호버 등)를 비활성화하므로 자식에 넣어야함) */
`;
