import styled from 'styled-components';
import '../../../assets/fonts/font.css';

export const EntireBox = styled.div`
  position: relative;
  width: 393px;
  display: inline-block;
  box-sizing: border-box;
`;

export const MyboardBox = styled.div`
  position: relative;
  margin-top: 80px;
`;

// 수정 완료버튼
export const FinishBtn = styled.button`
  position: absolute;
  top: 110px;
  right: 30px;

  width: 63px;
  height: 34px;

  background: #000000;
  border-radius: 25px;
  border: none;

  font-family: 'PretendardRegular';
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
  text-align: center;
  color: #ffffff;

  // 버튼 클릭
  :active {
    border: 1px solid #ffffff;
  }
`;

// export const TmiCardMiniBox = styled.div`
//   background: green;
//   position: relative;
//   display: flex;
//   flex-wrap: wrap;
//   text-align: center;
//   padding-left: 17px;
//   padding-top: 24px;
// `;

export const TmiCardMiniBox2 = styled.div`
  text-align: left;
  padding-left: 18px;
`;

export const TmiCardLargeBox = styled.div`
  text-align: center;
`;

export const TmiCardView = styled.div`
  margin-top: 24px;
  text-align: center;
`;

export const ProfileBox = styled.div`
  position: relative;
  text-align: -webkit-center;
`;

export const EmojiBox = styled.div`
  position: absolute;
  top: -41%;
  left: 35%;
  width: 100px;
  height: 100px;
  font-size: 80px;
`;

export const TextName = styled.p`
  margin: 0px;
  padding: 0px;
  font-family: 'Pretendard';
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  text-align: center;

  color: #000000;
`;

export const SettingBtn = styled.img`
  width: 38px;
  height: 38px;
  top: 8px;
  right: 35px;
  position: absolute;
  // 버튼 클릭
  :active {
    border: 1px solid #ffffff;
  }
`;
