import React from 'react';
import logoTitle from '../assets/images/logo_title_mini.png';
import { EntireBox } from './header.styled';
const Header = () => {
  const onClick = () => {
    if (localStorage.getItem('loginUserId') === null) {
      window.location.replace(`/`);
    } else {
      window.location.replace(
        `/tmiboard/${localStorage.getItem('loginUserId')}`
      );
    }
  };
  return (
    <EntireBox onClick={onClick}>
      <div className='logo-container' onClick={onClick}>
        <img src={logoTitle} alt='' onClick={onClick} />
      </div>
    </EntireBox>
  );
};

export default Header;
