import styled from 'styled-components';
import '../../../assets/fonts/font.css';

export const EntireBox = styled.div`
  box-sizing: border-box;
  max-width: 393px;
  margin: auto;
`;

export const HeaderBox = styled.div`
  padding-top: 60px;
  padding-left: 42px;
  display: flex;
  // justify-content: space-between;
  margin: 0; /* 기본 마진 제거 */
`;

export const TitleBox = styled.div`
  display: block;
  text-align: left;
  align-items: left;
`;

export const MainLogo = styled.img`
  display: block; /* 인라인 요소를 블록 요소로 변경하여 세로 정렬 */
  margin-right: 50px; /* 버튼과의 간격 */
`;

export const TitleBox2 = styled.div`
  /* padding-left: 80px; */
  display: block;
  text-align: left;
  align-items: left;
`;

export const NextButton = styled.button`
  width: 63px;
  height: 34px;

  background: #000000;
  border-radius: 25px;

  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
  text-align: center;

  color: #ffffff;
`;

export const Introduce = styled.div`
  box-sizing: border-box;
  padding-top: 40px;
  margin: 0; /* 기본 마진 제거 */
`;

export const EmojiBox = styled.div`
  text-align: -webkit-center;
`;

export const EmojiValue = styled.div`
  width: 80px;
  height: 80px;
  font-size: 60px;
  // 이모지 있으면 투명색
  background: ${(props: any) => (props.children ? 'transparent' : '#a46dff')};
  border-radius: 50%;
`;

export const Text1 = styled.span`
  text-align: center;
  font-family: 'PretendardMedium';
  // justify-content: center;
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 12px;
  color: #b1b1b1;
`;

export const InputBoxs = styled.div`
  padding-top: 15px;
  width: calc(100% - 60px);
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Text2 = styled.p`
  text-align: center;
  font-family: 'GmarketSansMedium';
  justify-content: center;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  color: #9e9e9e;
`;

export const Hello = styled.p`
  font-family: 'GmarketSansMedium';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 18px;
  color: #000000;

  margin: 0; /* 기본 마진 제거 */
`;
