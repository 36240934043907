import React, { useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import {
  Hello,
  MainLogo,
  LoginText,
  GreetingBox,
  EntireBox,
} from '../pages/SignIn/SignIn1/signIn1.styled';
import {
  Default,
  // Desktop,
  Mobile,
  // Tablet,
} from '../components/MediaQuery';
import logoTitle from '../assets/images/logo_title.png';
// import axios from 'axios';
// import { useNavigate } from 'react-router-dom';
const KakaoLogin = (props: any) => {
  const navigate = useNavigate();
  let code = new URL(window.location.href).searchParams.get('code'); // 인가코드

  useEffect(() => {
    const api = axios.create({
      baseURL: process.env.REACT_APP_API_URL,
    });

    api
      .post(`users/kakao?token=${code}`)
      .then(function (response) {
        // const ACCESS_TOKEN = response.data.accessToken;
        console.log('re', response);
        const loginUserId = response.data.result.userId;
        const loginUserName = response.data.result.userName;
        const loginJwt = response.data.result.jwt;
        localStorage.setItem('loginUserId', loginUserId); //예시로 로컬에 저장함
        localStorage.setItem('loginJwt', loginJwt);
        localStorage.setItem('loginUserName', loginUserName);

        if (response.data.result.myboardStatus === 'Y') {
          // 메인으로 들어가기.
          navigate(`/tmiboard/${loginUserId}`);
        } else {
          // 마이보드 입력 사이트로 들어가기
          navigate(`/signin2`);
        }
      })
      .catch(function (error) {
        window.alert('로그인에 실패하였습니다.');
        localStorage.removeItem('loginUserId');
        localStorage.removeItem('loginJwt');
        localStorage.removeItem('loginUserName');
        //  history.replace('/login'); // 로그인 실패하면 로그인화면으로 돌려보냄
      })
      .then(function () {
        console.log('END');
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div>
        <Mobile>
          <>
            <EntireBox>
              <GreetingBox>
                <Hello>hello!</Hello>
                <MainLogo src={logoTitle} alt='' />
              </GreetingBox>
              <LoginText>잠시만 기다려주세요.</LoginText>
            </EntireBox>
          </>
        </Mobile>
        <Default>
          <br></br>
        </Default>
      </div>
    </>
  );
};

export default KakaoLogin;
