import React, { useState, useRef, useEffect } from 'react';
import axios from 'axios';
import {
  EntireBox,
  LargeBox,
  ContentsBox,
  TopBox,
  EmojiBox,
  EmojiText,
  TitleTextBox,
  TitleText,
  HashTagBox,
  CommentBox,
  CommentText,
  ButtonBox,
  ColorChip,
  ColorBox,
  HashTagAddBtn,
  InputTextHashTag,
  FinishBtn,
} from './adminTmiCardLarges.styled';
import EmojiPicker, { EmojiClickData } from 'emoji-picker-react';
import HashTagContentsWhite from '../HashTagContentsWhite';
import { useNavigate } from 'react-router-dom';

export interface AddTmiCardProps {
  data: TmiCardLargesAllProps;
  addTmiCardData: TmiCardLargesAllProps[];
  setAddTmiCardData: React.Dispatch<
    React.SetStateAction<TmiCardLargesAllProps[]>
  >;
  setIsTmiCardUptMode: React.Dispatch<React.SetStateAction<boolean>>;
}
// 각 카드 한개에 대한 정보
export interface TmiCardLargesAllProps {
  cardId: number;
  color: string;
  emoji: string;
  title: string;
  hashTag: string[];
  comments: string;
  onClick?: any;
  greatCount: number;
  heartCount: number;
  clapCount: number;
  selectedInteract: string;
  onClickCard?: any;
}

const AdminTmiCardLarge = ({
  data,
  addTmiCardData,
  setAddTmiCardData,
  setIsTmiCardUptMode,
}: AddTmiCardProps) => {
  console.log('업데이트 컴포넌트');
  const [isSelectEmoji, setIsSelectEmoji] = useState<boolean>(false);
  const [isInputHashTag, setIsInputHashTag] = useState<boolean>(false);
  const inputHashTagRef = useRef<HTMLInputElement>(null);
  const navigate = useNavigate();

  const api = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
  });

  /** isInputHashTag가 true될 때 focus  */
  useEffect(() => {
    if (isInputHashTag && inputHashTagRef.current) {
      inputHashTagRef.current.focus();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isInputHashTag]);

  /** 이모지 클릭 */
  const onClickEmoji = (emojiData: EmojiClickData, index: number) => {
    const { emoji } = emojiData;
    const newData = { ...data, emoji: emoji }; // 변경된 data 객체 생성

    const newDataArray = addTmiCardData.map((item) => {
      // 기존 addTmiCardData 데이터 배열에서 수정된 데이터를 찾아서 새로운 배열 생성
      if (item.cardId === data.cardId) {
        return newData;
      } else {
        return item;
      }
    });

    console.log(newDataArray);

    setAddTmiCardData(newDataArray);
    setIsSelectEmoji(!isSelectEmoji);
  };

  const onClickEmojiCha = () => {
    setIsSelectEmoji(!isSelectEmoji);
  };

  const onChangeColor = (event: any) => {
    // const clickColor = event.target.getAttribute('color');
    const changeColor = event.target.attributes.color.nodeValue;
    const newData = { ...data, color: changeColor }; // 변경된 data 객체 생성

    const newDataArray = addTmiCardData.map((item) => {
      // 기존 addTmiCardData 데이터 배열에서 수정된 데이터를 찾아서 새로운 배열 생성
      if (item.cardId === data.cardId) {
        return newData;
      } else {
        return item;
      }
    });

    setAddTmiCardData(newDataArray);
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    console.log(event.target.value);
  };

  const onClickAddHashTag = (event: any) => {
    if (!isInputHashTag) {
      setIsInputHashTag(true); // 입력란 출력

      if (inputHashTagRef.current) {
        inputHashTagRef.current.focus();
      }
    }

    //  data.cardId =  선택한 트미카드 번호
  };

  const handleInputBlur = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.value.replaceAll(' ', '').length !== 0) {
      // 공백이 아닌경우만
      const inputText = '#' + event.target.value.replaceAll(' ', '');
      const newHash = [...data.hashTag, inputText];

      const updatedData = addTmiCardData.map((item) => {
        if (item.cardId === data.cardId) {
          return { ...item, hashTag: newHash };
        }
        return item;
      });

      setAddTmiCardData(updatedData);
    }
    setIsInputHashTag(false);
  };

  /** 입력값이 변경될 때마다 */
  const onChangeInput = (event: any) => {
    const { id, value } = event.target;

    const updatedData = addTmiCardData.map((item) => {
      if (item.cardId === data.cardId) {
        if (id === 'comments') {
          return { ...item, comments: value };
        } else if (id === 'title') {
          return { ...item, title: value };
        }
      }
      return item;
    });

    setAddTmiCardData(updatedData);
  };

  const funTest = async () => {
    console.log('수정완료데이터', data);
    const userId = localStorage.getItem('loginUserId');
    api
      .put(
        `tmicard/update/${data.cardId}`,
        {
          userId: Number(userId),
          cardEmoji: data.emoji,
          cardColor: data.color,
          title: data.title ?? '',
          comments: data.comments,
          hashTagList: data.hashTag,
        },
        {
          headers: {
            'X-ACCESS-TOKEN': localStorage.getItem('loginJwt'),
          },
        }
      )
      .then(function (response: any) {
        console.log(response);
        setIsTmiCardUptMode(false);
      })
      .catch(function (error: any) {
        console.log('작성 에러', error);
        window.alert('트미카드 수정에 실패하였습니다.');
        navigate(''); // 로그인 실패하면 로그인화면으로 돌려보냄
      })
      .then(function () {});
  };

  return (
    <EntireBox>
      <LargeBox color={data.color} id={`${data.cardId}`} key={data.cardId}>
        <FinishBtn onClick={funTest}>완료</FinishBtn>
        <ContentsBox>
          <TopBox>
            <EmojiBox onClick={onClickEmojiCha}>
              <EmojiText>{data.emoji}</EmojiText>
              {isSelectEmoji && (
                <EmojiPicker
                  height={350}
                  autoFocusSearch={false}
                  onEmojiClick={(emojiData) =>
                    onClickEmoji(emojiData, data.cardId)
                  }
                />
              )}
            </EmojiBox>
            <TitleTextBox>
              <TitleText
                type='text'
                id='title'
                placeholder='주제'
                onChange={onChangeInput}
                value={data.title}
              ></TitleText>
            </TitleTextBox>
          </TopBox>
          <HashTagBox>
            <HashTagContentsWhite
              array={data.hashTag ?? []}
            ></HashTagContentsWhite>
            {isInputHashTag && (
              <InputTextHashTag
                type='text'
                id='inputHashTag'
                ref={inputHashTagRef}
                onChange={handleInputChange}
                onBlur={handleInputBlur}
                placeholder='입력'
              ></InputTextHashTag>
            )}
            <HashTagAddBtn onClick={onClickAddHashTag}>···</HashTagAddBtn>
          </HashTagBox>
          <CommentBox>
            <CommentText
              type='textarea'
              id='comments'
              placeholder='주제에 대한 자유 코멘트를 입력해주세요.'
              onChange={onChangeInput}
              value={data.comments}
            ></CommentText>
          </CommentBox>
          <ButtonBox>
            <ColorBox>
              <ColorChip color='#D5DAEE' id={`1`} onClick={onChangeColor} />
              <ColorChip color='#CFE6F5' id={`2`} onClick={onChangeColor} />
              <ColorChip color='#CBF1F0' id={`3`} onClick={onChangeColor} />
              <ColorChip color='#EAD2E5' id={`4`} onClick={onChangeColor} />
              <ColorChip color='#DDEAE5' id={`5`} onClick={onChangeColor} />
              <ColorChip color='#DBE5E3' id={`6`} onClick={onChangeColor} />
              <ColorChip color='#94C8CC' id={`7`} onClick={onChangeColor} />
              <ColorChip color='#FFDCDC' id={`8`} onClick={onChangeColor} />
              <br />
              <ColorChip color='#E2EC98' id={`9`} onClick={onChangeColor} />
              <ColorChip color='#FDE27E' id={`10`} onClick={onChangeColor} />
              <ColorChip color='#FAEE9E' id={`11`} onClick={onChangeColor} />
              <ColorChip color='#FFF5E2' id={`12`} onClick={onChangeColor} />
              <ColorChip color='#FACBE5' id={`13`} onClick={onChangeColor} />
              <ColorChip color='#F1A2B4' id={`14`} onClick={onChangeColor} />
              <ColorChip color='#E6FADF' id={`15`} onClick={onChangeColor} />
              <ColorChip color='#52DF88' id={`16`} onClick={onChangeColor} />
            </ColorBox>
          </ButtonBox>
        </ContentsBox>
      </LargeBox>
    </EntireBox>
  );
};

export default AdminTmiCardLarge;
