/**
 * 트미보드 업데이트 화면
 */
import React, { useEffect, useState } from 'react';
import axios from 'axios';
// import EmojiPicker, { EmojiClickData } from 'emoji-picker-react';

// import { useParams } from 'react-router-dom';
import { Default, Mobile } from '../../../components/MediaQuery';
import Header from '../../../components/Header';
import Footer from '../../../components/Footer';
import {
  EntireBox,
  MyboardBox,
  ProfileBox,
  TextName,
  EmojiBox,
  FinishBtn,
} from './updateTmiBoard.styled';
import {
  UrlBox,
  UrlText,
  SnsIconBox,
  SnsIcon,
  UrlInputs,
} from '../../SignIn/SignIn3/signIn3.styled';
import ProfileCard from '../../../components/ProfileCard';
import { useNavigate } from 'react-router-dom';
import TextBox from '../../../components/TextBox';
import logo_bondi from '../../../assets/images/logo_bondi.png';
import logo_brunch from '../../../assets/images/logo_brunch.png';
import logo_instagram from '../../../assets/images/logo_instagram.png';
import logo_kakaotalk from '../../../assets/images/logo_kakaotalk.png';
import logo_naverblog from '../../../assets/images/logo_naverblog.png';
import logo_tiktok from '../../../assets/images/logo_tiktok.png';
import logo_tistory from '../../../assets/images/logo_tistory.png';

export interface TmiCardLargeProps {
  cardId: number;
  cardEmoji: string;
  cardColor: string;
  title: string;
  comments: string;
  hashTag: string[];
}

export interface MyboardInfo {
  boardId?: number;
  cardId: number;
  emoji: string;
  name: string;
  mbti: string;
  birth: string;
  publicBirth: string;
  comment: string;
  snsInfo: SNSInfo[];
}

const initMyboardInfo: MyboardInfo = {
  boardId: 0,
  cardId: 0,
  emoji: '',
  name: '',
  mbti: '',
  birth: '',
  publicBirth: '',
  comment: '',
  snsInfo: [],
};

const testData: MyboardInfo = {
  boardId: 1,
  cardId: 0,
  emoji: '👩‍🎨',
  name: '테스트',
  mbti: 'TEST',
  birth: '970214',
  publicBirth: 'VISIBLE',
  comment: '안뇽테스트',
  snsInfo: [],
};

export interface SNSInfo {
  urlType: string;
  url: string;
}

const UpdateTmiBoard = () => {
  //   const { userId } = useParams();
  const [myboardData, setMyboardData] = useState<MyboardInfo>(initMyboardInfo);
  //   const navigate = useNavigate();
  const [selectedSNSData, setSelectedSNSData] = useState<SNSInfo[]>([]);
  const navigate = useNavigate();
  const loginUserId: number = Number(localStorage.getItem('loginUserId')) ?? 0;
  const api = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
  });

  useEffect(() => {
    // 마이보드 정보 API 요청
    getMyboard();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /* 마이보드 정보 */
  const getMyboard = async () => {
    api
      .get(`myboard?userId=${localStorage.getItem('loginUserId')}`)
      .then(function (response) {
        const resResult = response.data.result;

        // snsInfo 배열 생성
        let getSnsArr: SNSInfo[] = [];
        if (resResult.url1Type !== '') {
          const snsObj: SNSInfo = {
            urlType: resResult.url1Type,
            url: resResult.url1,
          };

          getSnsArr.push(snsObj);
        }
        if (resResult.url2Type !== '') {
          const snsObj: SNSInfo = {
            urlType: resResult.url2Type,
            url: resResult.url2,
          };

          getSnsArr.push(snsObj);
        }
        if (resResult.url3Type !== '') {
          const snsObj: SNSInfo = {
            urlType: resResult.url3Type,
            url: resResult.url3,
          };

          getSnsArr.push(snsObj);
        }

        const addData: MyboardInfo = {
          boardId: resResult.boardId,
          cardId: resResult.cardId,
          emoji: resResult.emoji,
          name: resResult.name,
          mbti: resResult.mbti,
          birth: resResult.birth,
          publicBirth: resResult.publicBirth,
          comment: resResult.myboardComments,
          snsInfo: getSnsArr,
        };

        setSelectedSNSData(getSnsArr);
        setMyboardData(addData);
        console.log('마이보드 조회완료', resResult);
      })
      .catch(function (error) {
        console.log('마이보드 조회 실패', error);
        window.alert('마이보드 조회 실패');
        // 테스트데이터
        setSelectedSNSData([]);
        setMyboardData(testData);
      })
      .then(function () {});
  };

  const onClickSnsIcon = (event: any) => {
    const clickedId = event.target.id;

    if (selectedSNSData.length < 3) {
      const newSNSArray = {
        urlType: clickedId,
        url: '',
      };
      setSelectedSNSData([...selectedSNSData, newSNSArray]);
    } else {
      window.alert('3개까지 추가 가능합니다.');
    }
  };

  const onChangeUrl = (event: any) => {
    const inputId = event.target.name; // 카카오톡, 틱톡 등
    const inputUrlValue = event.target.value; // url

    // id뒤에 숫자가, 배열의 index와 같다.
    selectedSNSData[inputId.substr(3, 4) - 1].url = inputUrlValue;
    setSelectedSNSData([...selectedSNSData]);
  };

  /* 완료 버튼 클릭 (업데이트 완료)*/
  const onClickFinish = () => {
    api
      .put(
        `myboard`,
        {
          userId: loginUserId,
          emoji: myboardData.emoji,
          birthStatus: 'VISIBLE',
          name: myboardData.name,
          mbti: myboardData.mbti,
          birth: myboardData.birth.replaceAll('.', ''),
          myboardComments: myboardData.comment,
          url1: selectedSNSData.length >= 1 ? selectedSNSData[0].url : '',
          url1Type:
            selectedSNSData.length >= 1 ? selectedSNSData[0].urlType : '',
          url2: selectedSNSData.length >= 2 ? selectedSNSData[1].url : '',
          url2Type:
            selectedSNSData.length >= 2 ? selectedSNSData[1].urlType : '',
          url3: selectedSNSData.length >= 3 ? selectedSNSData[2].url : '',
          url3Type:
            selectedSNSData.length >= 3 ? selectedSNSData[2].urlType : '',
        },
        {
          headers: {
            'X-ACCESS-TOKEN': localStorage.getItem('loginJwt'),
          },
        }
      )
      .then(function (response) {
        navigate('/tmiboard');
      })
      .catch(function (error) {
        console.log('마이보드 수정 에러', error);
        window.alert('마이보드 수정 실패하였습니다.');
      })
      .then(function () {});
  };

  const onClickDeleteSnsIcon = (event: any) => {
    const selectedSnsIndex = event.target.id;

    console.log(selectedSnsIndex);
    // 삭제하기 위해 눌렀으면,
    if (selectedSnsIndex !== '') {
      const confirm = window.confirm(
        `${
          selectedSNSData[parseInt(selectedSnsIndex) - 1].urlType
        } 정보를 삭제할까요?`
      );
      if (confirm) {
        const filteredSNSData = selectedSNSData.filter(
          (snsData, index) => index + 1 !== parseInt(selectedSnsIndex)
        );
        setSelectedSNSData(filteredSNSData);
      }
    }
  };

  return (
    <div>
      <Mobile>
        <EntireBox>
          <Header />
          <FinishBtn onClick={onClickFinish}>완료</FinishBtn>
          <MyboardBox>
            <ProfileBox>
              <EmojiBox>
                {myboardData.emoji}
                <TextName>{myboardData.name}</TextName>
                {/* {isSelectEmoji && (
                  <EmojiPicker
                    height={350}
                    // width=''
                    autoFocusSearch={false}
                    onEmojiClick={onClickEmojiPicker}
                  />
                )} */}
              </EmojiBox>
              <ProfileCard
                intro={myboardData.comment}
                birth={myboardData.birth}
                mbti={myboardData.mbti}
                snsInfo={selectedSNSData}
                onClickSns={onClickDeleteSnsIcon}
              />
            </ProfileBox>
          </MyboardBox>
          <UrlBox>
            <UrlText>
              오른쪽 세 개의 아이콘 영역에 채워질 SNS 주소를 입력해주세요. (생략
              가능)
            </UrlText>
            <SnsIconBox>
              <SnsIcon
                src={logo_kakaotalk}
                alt=''
                onClick={onClickSnsIcon}
                id='kakaoTalk'
              />
              <SnsIcon
                src={logo_instagram}
                alt=''
                onClick={onClickSnsIcon}
                id='instagram'
              />
              <SnsIcon
                src={logo_tiktok}
                alt=''
                onClick={onClickSnsIcon}
                id='tiktok'
              />
              <SnsIcon
                src={logo_naverblog}
                alt=''
                onClick={onClickSnsIcon}
                id='naverblog'
              />
              <SnsIcon
                src={logo_tistory}
                alt=''
                onClick={onClickSnsIcon}
                id='tistory'
              />
              <SnsIcon
                src={logo_brunch}
                alt=''
                onClick={onClickSnsIcon}
                id='brunch'
              />
              <SnsIcon
                src={logo_bondi}
                alt=''
                onClick={onClickSnsIcon}
                id='bondi'
              />
            </SnsIconBox>
            <UrlInputs>
              {selectedSNSData.map((snsData: SNSInfo, index) => (
                <TextBox
                  placeholder='URL'
                  name={`url${index + 1}`}
                  key={`url${index + 1}`}
                  onChange={onChangeUrl}
                  value={snsData.url}
                />
              ))}
            </UrlInputs>
          </UrlBox>
        </EntireBox>
      </Mobile>
      <Default>
        <br></br>
      </Default>
      <Footer />
    </div>
  );
};

export default UpdateTmiBoard;
