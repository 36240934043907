import React from 'react';
import './textBox.css';

const TextBox = ({ name, placeholder, onChange, value }: any) => {
  return (
    <>
      <input
        type='text'
        name={name}
        placeholder={placeholder}
        className='input'
        onChange={onChange}
        value={value}
      ></input>
    </>
  );
};

export default TextBox;
