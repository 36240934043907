import React, { useEffect } from 'react';
import {
  Hello,
  MainLogo,
  LoginText,
  KakaoButton,
  GreetingBox,
  LoginWrap,
  ImgKakao,
  EntireBox,
} from './signIn1.styled';
import {
  Default,
  // Desktop,
  Mobile,
  // Tablet,
} from '../../../components/MediaQuery';
import logoTitle from '../../../assets/images/logo_title.png';
import imgKakao from '../../../assets/images/logo_signinkakao.png';

const Index = () => {
  useEffect(() => {
    console.log(localStorage.getItem('loginUserId'));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // const navigate = useNavigate();
  // 카카오 로그인 API 호출 URL
  const requestUrl = `https://kauth.kakao.com/oauth/authorize?client_id=${process.env.REACT_APP_KAKAOLOGIN_RESTAPI_KEY}&redirect_uri=${process.env.REACT_APP_REDIRECT_URI}&response_type=code`;

  /* 카카오 로그인 API 요청 */
  const onClickKakaoLogin = () => {
    window.location.href = requestUrl;
  };

  return (
    <div>
      <Mobile>
        <>
          <EntireBox>
            <GreetingBox>
              <Hello>hello!</Hello>
              <MainLogo src={logoTitle} alt='' />
            </GreetingBox>
            <LoginWrap>
              <KakaoButton onClick={onClickKakaoLogin}>
                <ImgKakao src={imgKakao} alt='' />
                <span>카카오 로그인</span>
              </KakaoButton>
            </LoginWrap>
            <LoginText>로그인 후 이용하실 수 있습니다.</LoginText>
          </EntireBox>
        </>
      </Mobile>
      <Default>
        <br></br>
      </Default>
    </div>
  );
};

export default Index;
