import styled from 'styled-components';
import '../../assets/fonts/font.css';

export const EntireBox = styled.div`
  box-sizing: border-box; /* 자식을 줄여라 */
  text-align: -webkit-center;
  display: flex;
  flex-direction: column-reverse;
`;

// 업데이트 카드
export const LargeBox = styled.div`
  max-width: 100%;
  box-sizing: border-box; /* 자식을 20px 줄여라 */
  width: 330px;
  height: 330px;
  box-shadow: 3px 4px 6px rgba(0, 0, 0, 0.1);
  background: ${(props) => props.color};
  border-radius: 45px;
  padding-top: 35px;
  position: relative;
  margin-bottom: 20px;
  align-self: center;
  & + & {
    margin-top: 20px;
  }
`;

export const ContentsBox = styled.div`
  box-sizing: border-box; /* 자식을 20px 줄여라 */
  padding-left: 30px;
  padding-right: 30px;
  padding-bottom: 20px;
`;

export const TopBox = styled.div`
  display: flex;
  align-items: center;
  display: flex;
  items: center;
`;

export const EmojiBox = styled.div`
  width: 70px;
  height: 70px;
`;

export const EmojiText = styled.span`
  font-weight: 400;
  font-size: 55px;
  line-height: 74px;
  text-align: center;
  vertical-align: middle;
`;

export const TitleTextBox = styled.div`
  margin-top: 15px;
  align-items: center;
  background: transparent;
`;

export const InputTextHashTag = styled.input`
  width: 45px;
  height: 13px;
  color: #000000;
  font-family: 'PretendardMedium';
  font-style: normal;
  font-weight: 600;
  font-size: 11px;
  line-height: 13px;

  margin-left: 10px;
  background: transparent;

  text-align: left;
  color: #000000;

  &::placeholder {
    color: #666666;
    font-family: 'PretendardMedium';
    font-weight: 800;
    font-size: 10px;
    line-height: 36px;
    text-align: left;
  }
`;

export const TitleText = styled.input`
  margin-left: 5px;
  border: none;
  background: transparent;
  font-family: 'PretendardBold';
  font-weight: 800;
  font-size: 30px;
  line-height: 36px;
  text-align: left;
  color: #000000;
  width: 195px;

  &::placeholder {
    color: #666666;
    font-family: 'PretendardMedium';
    font-weight: 800;
    font-size: 30px;
    line-height: 36px;
    text-align: left;
  }
`;

export const ColorBox = styled.div`
  padding-top: 10px;
`;

export const ColorChip = styled.div`
  display: inline-block;
  background: ${(props) => props.color};
  width: 25px;
  height: 25px;
  border-radius: 10px;
  & + & {
    margin-left: 10px;
  }
  br + & {
    margin-top: 4px;
  }
`;

export const HashTagBox = styled.div`
  text-align: left;
  width: 264px;
  height: 62px;
  margin-top: 12px;
  overflow: hidden;
`;

export const ButtonBox = styled.div`
  text-align: center;
  display: flex;
`;
export const ButtonIcon = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 63px;
  height: 63px;
  border: none;
  background: #ffffff;
  border-radius: 41px;

  & + & {
    margin-left: 40px;
  }
`;

export const ButtonImg = styled.img`
  width: 35px;
  height: 35px;
`;
// fdfs
export const TmiLargeBox = styled.div`
  width: 330px;
  height: 330px;
  box-shadow: 3px 4px 6px rgba(0, 0, 0, 0.1);
  background: ${(props) => props.color};
  border-radius: 45px;
  padding: 35px 30px 35px 30px;
  box-sizing: border-box; /* 자식을 20px 줄여라 */
`;

export const TitleBox = styled.div`
  display: flex;
  vertical-align: middle;
  height: 60px;
  align-items: center;
`;

export const HashTagText = styled.span`
  border: 1px solid #000000;
  border-radius: 30px;
  padding: 6px 12px 6px 12px;
  font-family: 'PretendardSemiBold';
  font-weight: 600;
  font-size: 11px;
  line-height: 13px;

  & {
    margin-right: 6px;
    margin-top: 6px;
  }
`;

export const CommentBox = styled.div`
  // background: red;
  box-sizing: border-box; /* 자식을 20px 줄여라 */
  min-width: 264px;
  max-width: 60px;
  text-align: left;
`;

export const CommentText = styled.input`
  display: block; /* 가로폭을 꽉 채우도록 변경 */
  width: 100%;
  height: 60px;
  font-family: 'PretendardRegular';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #000000;

  border: none;
  outline: none;
  background-color: transparent;
  resize: none;
  white-space: pre-wrap;
  overflow-wrap: normal;
`;

export const CommentTextArea = styled.textarea`
  display: block; /* 가로폭을 꽉 채우도록 변경 */
  width: 100%;
  height: 60px;
  font-family: 'PretendardRegular';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #000000;

  border: none;
  outline: none;
  background-color: transparent;
  white-space: pre-wrap;
  overflow-wrap: normal;

  /* 추가된 CSS */
  overflow: hidden;
  text-overflow: ellipsis;
  resize: none;
  max-height: 45px; // 3줄 작성 17x3
`;

export const HashTagAddBtn = styled.button`
  border: none;
  outline: none;
  background-color: transparent;

  width: 9px;
  height: 13px;

  font-family: 'PretendardMedium';
  font-style: normal;
  font-weight: 600;
  font-size: 11px;
  line-height: 13px;

  color: #000000;
`;

// 수정 완료버튼
export const FinishBtn = styled.button`
  position: absolute;
  top: 24px;
  right: 24px;

  width: 48px;
  height: 28px;

  background: #000000;
  border-radius: 25px;
  border: none;

  font-family: 'PretendardRegular';
  font-weight: 500;
  font-size: 11px;
  line-height: 13px;
  text-align: center;
  color: #ffffff;

  // 버튼 클릭
  :active {
    border: 1px solid #ffffff;
  }
`;
