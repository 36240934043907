import React from 'react';
import { Mobile } from '../components/MediaQuery';
import Header from '../components/Header';
import Footer from '../components/Footer';

const NotFound = () => {
  return (
    <div>
      {/* <Desktop>
        <p>NOT FOUND</p>
      </Desktop>
      <Tablet>
        <p>NOT FOUND</p>
      </Tablet> */}
      <Mobile>
        <>
          <Header />
          <p>
            NOT FOUND
            <br />
            관리자에게 문의하세요.
          </p>
        </>
      </Mobile>
      <Footer />
    </div>
  );
};

export default NotFound;
