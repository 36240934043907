import React from 'react';
import './button.css';

/**
 * @param text: 버튼 Text
 * @param onClick: 버튼 함수 */
const ButtonSmall = ({ text, onClick }: any) => {
  return (
    <>
      <button className='button' onClick={onClick}>
        {text}
      </button>
    </>
  );
};

export default ButtonSmall;
