import React, { useState } from 'react';
import EmojiPicker, { EmojiClickData } from 'emoji-picker-react';
import {
  HeaderBox,
  MainLogo,
  Hello,
  NextButton,
  Introduce,
  InputBoxs,
  Text1,
  Text2,
  TitleBox,
  TitleBox2,
  EmojiValue,
  EntireBox,
  EmojiBox,
} from './signIn2.styled';
import { Default } from '../../../components/MediaQuery';
import logoTitle from '../../../assets/images/logo_title_mini.png';
import TextBox from '../../../components/TextBox';
import { useNavigate } from 'react-router-dom';
export interface UserProfileInfo {
  userEmoji: string;
  inputName: string;
  inputMbti: string;
  inputBirth: string;
  inputPublicBirth: boolean;
  inputComment: string;
}

const INIT_UserProfileInfo = {
  userEmoji: '',
  inputName: '',
  inputMbti: '',
  inputBirth: '',
  inputPublicBirth: false,
  inputComment: '',
};

const SignIn2 = () => {
  const navigate = useNavigate();
  const [isSelectEmoji, setIsSelectEmoji] = useState<boolean>(false);
  const [userProfile, setUserProfile] =
    useState<UserProfileInfo>(INIT_UserProfileInfo);

  /* 다음 버튼 클릭 */
  const onClickNext = () => {
    // 입력값 검증
    if (userProfile.inputName.length === 0) {
      window.alert('닉네임을 입력하세요.');
      return;
    }
    if (userProfile.inputMbti.length !== 4) {
      window.alert('MBTI를 입력하세요. (ex. ENFP)');
      return;
    }
    if (userProfile.inputBirth.length !== 6) {
      window.alert('생일은 6자리로 입력하세요.');
      return;
    }
    if (userProfile.inputComment.length === 0) {
      window.alert('소개글을 입력하세요.');
      return;
    }
    if (userProfile.userEmoji === '') {
      window.alert('이모지를 선택하세요.');
      return;
    }
    // console.log(userProfile);
    console.log('사인2에서', localStorage.getItem('loginUserId'));

    localStorage.setItem('MyboardData', JSON.stringify(userProfile));
    // /signin3 으로 이동, 뒤로가기 금지, MyboardData 로 입력값 저장
    navigate(`/signin3`, { replace: true });

    // 배열을 불러오기
    // const storedTodos = JSON.parse(localStorage.getItem('todos'));
    // console.log(storedTodos);
  };

  const onEmojiBox = () => {
    setIsSelectEmoji(!isSelectEmoji);
  };

  const onClickEmojiPicker = (emojiData: EmojiClickData) => {
    // console.log('이모지를 누르면 나오는거', emojiData.emoji);
    const newUserProfile = {
      userEmoji: emojiData.emoji,
      inputName: userProfile.inputName,
      inputMbti: userProfile.inputMbti,
      inputBirth: userProfile.inputBirth,
      inputPublicBirth: userProfile.inputPublicBirth,
      inputComment: userProfile.inputComment,
    };
    setIsSelectEmoji(!isSelectEmoji);
    setUserProfile(newUserProfile);
  };

  /** 입력값이 변경될 때마다 */
  const onChangeInput = (event: any) => {
    const id = event.target.name;
    let inputValue = event.target.value;
    // console.log(inputValue);

    if (id === 'inputMbti') {
      inputValue = event.target.value.toUpperCase();
    }

    // console.log(inputValue);

    const inputJson = `{"${id}":"${inputValue}"}`;
    // console.log(inputJson);
    const obj = JSON.parse(inputJson);

    setUserProfile({ ...userProfile, ...obj });
  };

  return (
    <div>
      <>
        <EntireBox>
          <HeaderBox>
            {' '}
            {/* 헬로, tmiBoard, 다음버튼 */}
            <TitleBox>
              {' '}
              {/*  */}
              <Hello>&nbsp;&nbsp;hello!</Hello>
              <MainLogo src={logoTitle} alt='' />
            </TitleBox>
            <TitleBox2>
              <NextButton onClick={onClickNext}>다음</NextButton>
            </TitleBox2>
          </HeaderBox>
          <Introduce>
            <EmojiBox>
              <EmojiValue onClick={onEmojiBox}>
                {userProfile.userEmoji}
              </EmojiValue>
            </EmojiBox>
            {isSelectEmoji && (
              <EmojiPicker
                height={350}
                autoFocusSearch={false}
                onEmojiClick={onClickEmojiPicker}
              />
            )}
            <Text1>자신을 표현 할 이모지를 골라주세요.</Text1>
            <InputBoxs>
              <TextBox
                placeholder='Name'
                name='inputName'
                id='inputName'
                onChange={onChangeInput}
                value={userProfile.inputName}
              />
              <TextBox
                placeholder='MBTI'
                name='inputMbti'
                id='inputMbti'
                onChange={onChangeInput}
                value={userProfile.inputMbti}
              />
              <TextBox
                placeholder='Birth'
                name='inputBirth'
                id='inputBirth'
                onChange={onChangeInput}
                value={userProfile.inputBirth}
              />
              <TextBox
                placeholder='Comment'
                name='inputComment'
                id='inputComment'
                onChange={onChangeInput}
                value={userProfile.inputComment}
              />
            </InputBoxs>
            <Text2>자기 소개 영역입니다.</Text2>
          </Introduce>
        </EntireBox>
      </>
      <Default>
        <br></br>
      </Default>
    </div>
  );
};

export default SignIn2;
