import styled from 'styled-components';
import '../assets/fonts/font.css';

export const EntireBox = styled.div`
  display: inline-flex;
  flex-wrap: wrap; // div 요소 크기를 벗어났을 때
  gap: 8px; // flex-wrap 사이
  box-sizing: border-box; /* 자식을 줄여라 */
`;

export const HashTag = styled.div`
  box-sizing: border-box; /* 자식을 줄여라 */
  padding-left: 16px;
  padding-right: 16px;
  padding-top: 6px;
  padding-bottom: 6px;
  color: #ffffff;
  background: #000000;
  border-radius: 20px;
`;

export const HashTagText = styled.span`
  font-family: 'PretendardMedium';
  font-size: 14px;
  justify-content: center;
  align-items: center;
  display: flex;
`;
