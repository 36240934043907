import styled from 'styled-components';
import '../../../assets/fonts/font.css';
import plusButton from '../../../assets/images/plusButton.png';

export const EntireBox = styled.div`
  box-sizing: border-box;
  max-width: 393px;
  margin: auto;
  // display: inline-block;
`;

export const HeaderBox = styled.div`
  padding-top: 60px;
  padding-left: 42px;
  padding-right: 30px;
  display: flex;
  justify-content: space-between;
`;

export const TitleSign = styled.h1`
  font-family: 'PretendardRegular';
  font-size: 13px;
  line-height: 19px;
  color: #000000;
  text-align: left;

  color: #000000;
`;

export const SkipButton = styled.button`
  width: 80px;
  height: 34px;

  background: #000000;
  border-radius: 25px;

  margin-top: 8px;

  font-family: 'PretendardMedium';
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
  text-align: center;

  color: #ffffff;
`;

export const TitleButtonBox = styled.div`
  width: 64px;
  height: 34px;

  background: #000000;
  border-radius: 25px;

  color: #ffffff;
`;

export const ContentBox = styled.div`
  position: relative;
  margin: 56px 31px 0px;

  height: 330px;
  background: #e6e6e6;
  box-shadow: 3px 4px 6px rgba(0, 0, 0, 0.1);
  border-radius: 45px;
`;

export const PlusImg = styled.button`
  position: absolute;
  top: 0px;
  left: 50%;
  transform: translate(-50%, -50%);

  width: 40px;
  height: 40px;
  border: 0px;
  background-image: url(${plusButton});
  background-color: transparent;
`;

export const ContentTitle = styled.h2`
  position: relative;
  text-align: center;
  padding-top: 46px;

  font-weight: 800;
  font-size: 30px;
  line-height: 36px;
  /* identical to box height */

  color: rgba(0, 0, 0, 0.9);
`;

export const AddTmiCardBox = styled.div`
  box-sizing: border-box; /* 자식을 줄여라 */
`;

export const ExBigCardImg = styled.img`
  // max-width: 100%;
}`;

export const ExampleContent = styled.div``;
// @import '../assets/fonts/font.css';

// .text1 {
//   text-align: center;
//   font-family: 'PretendardMedium';
//   justify-content: center;
//   font-style: normal;
//   font-weight: 500;
//   font-size: 10px;
//   line-height: 12px;
//   color: #b1b1b1;
//   width: 294px;
//   height: 36px;
// }
