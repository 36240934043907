import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import TmiCardLarges from './TmiCardLarges';
// import styled from 'styled-components';
// export interface TmiCardLargeProps {
//   cardId: number;
//   cardEmoji: string;
//   cardColor: string;
//   title: string;
//   comments: string;
//   hashTag: Array<string>[];
// }
interface TmiCardCarouselProps {
  array: TmiCardCarouselAllProps[];
  setArray: React.Dispatch<React.SetStateAction<TmiCardCarouselAllProps[]>>;
}

export interface ModalDataProps {
  isOpenModal: boolean;
  setOpenModal: React.Dispatch<React.SetStateAction<boolean>>;
}

export interface TmiCardCarouselAllProps {
  cardId: number;
  color: string;
  emoji: string;
  title: string;
  hashTag: string[];
  comments: string;
  onClick?: any;
  greatCount: number;
  heartCount: number;
  clapCount: number;
  selectedInteract: string;
  onClickCard?: any;
}

const TmiCardCarousel = ({ array, setArray }: TmiCardCarouselProps) => {
  const settings = {
    dots: true,
    infinite: true,
    centerMode: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    variableWidth: true,
    arrows: false,
  };
  return (
    <div>
      <Slider {...settings}>
        {array.map((card: TmiCardCarouselAllProps) => (
          <div key={card.cardId}>
            <TmiCardLarges
              data={card}
              array={array}
              setArray={setArray}
            ></TmiCardLarges>
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default TmiCardCarousel;
