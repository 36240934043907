import styled from 'styled-components';
import '../assets/fonts/font.css';

export const EntireBox = styled.div`
  display: flex;
  justify-content: center;
  padding-top: 45px;
`;

export const HeardLogo = styled.img`
  width: 163px;
  height: 43px;
`;
