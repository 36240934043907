import React from 'react';
import { EntireBox } from './adminTmiCardLarges.styled';
// import EmojiPicker, { EmojiClickData } from 'emoji-picker-react';
// import HashTagContentsWhite from '../HashTagContentsWhite';
import { AddTmiCardProps } from '../../pages/SignIn/SignIn4/signIn4';
import AdminTmiCardLarge from './AdminTmiCardLarge';
// 각 카드 한개에 대한 정보
export interface TmiCardLargesAllProps {
  cardId: number;
  color: string;
  emoji: string;
  title: string;
  hashTag: string[];
  comments: string;
  onClick?: any;
  greatCount: number;
  heartCount: number;
  clapCount: number;
  onClickCard?: any;
}

const AdminTmiCardLarges = ({
  addTmiCardData,
  setAddTmiCardData,
}: AddTmiCardProps) => {
  return (
    <EntireBox>
      {addTmiCardData.map((row: any, index: number) => (
        <AdminTmiCardLarge
          data={row}
          key={index}
          addTmiCardData={addTmiCardData}
          setAddTmiCardData={setAddTmiCardData}
        ></AdminTmiCardLarge>
      ))}
    </EntireBox>
  );
};

export default AdminTmiCardLarges;
