import styled from 'styled-components';
import '../assets/fonts/font.css';

export const EntireBox = styled.div`
  box-sizing: border-box; /* 자식을 줄여라 */
  text-align: -webkit-center;

  // margin-left: 5px;
  margin-right: 5px;
`;

export const LargeBox = styled.div`
  box-sizing: border-box; /* 자식을 20px 줄여라 */
  width: 330px;
  height: 330px;
  box-shadow: 3px 4px 6px rgba(0, 0, 0, 0.1);
  background: ${(props: any) => props.color};
  border-radius: 45px;
  position: relative; // 상호작용 박스를 위해서
`;

export const ContentsBox = styled.div`
  box-sizing: border-box; /* 자식을 20px 줄여라 */
  padding-top: 15px;
  padding-left: 30px;
  padding-right: 30px;
  padding-bottom: 20px;
`;

export const TopBox = styled.div`
  width: 270px;
  display: flex;
  align-items: center;
`;

export const EmojiBox = styled.div`
  width: 60px;
  height: 60px;
  // padding-bottom: 10px;
  // display: table-cell;
`;

export const EmojiText = styled.span`
  font-weight: 400;
  font-size: 55px;
  line-height: 74px;
  text-align: center;
  // vertical-align: middle;
`;

export const TitleTextBox = styled.div`
  margin-left: 10px;
  margin-top: 15px;
  align-items: center;
`;

export const TitleText = styled.span`
  font-family: 'PretendardBold';
  font-weight: 800;
  font-size: 30px;
  line-height: 36px;
  text-align: center;
  color: #000000;
`;

export const HashTagBox = styled.div`
  text-align: left;
  height: 55px;
  margin-top: 12px;
`;

export const ButtonBox = styled.div`
  text-align: center;
  display: flex;
`;

export const LikeButtonBox = styled.div`
  display: flex;
  justify-content: right;
`;

export const ButtonIcon = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 63px;
  height: 63px;
  border: none;
  background: #ffffff;
  border-radius: 41px;

  & + & {
    margin-left: 40px;
  }
`;

export const LikeButtonIcon = styled.div`
  justify-content: right;
`;

export const AdminButtonImg = styled.img`
  width: 35px;
  height: 35px;
`;

export const LikeButtonImg = styled.img`
  box-sizing: border-box;
  width: 68px;
  height: 68px;

  border: 2px solid #ffffff;
  border-radius: 30px;
`;

export const TmiLargeBox = styled.div`
  width: 330px;
  height: 330px;
  box-shadow: 3px 4px 6px rgba(0, 0, 0, 0.1);
  background: ${(props: any) => props.color};
  border-radius: 45px;
  padding: 35px 30px 35px 30px;
  box-sizing: border-box; /* 자식을 20px 줄여라 */
`;

export const TitleBox = styled.div`
  display: flex;
  vertical-align: middle;
  height: 60px;
  align-items: center;
`;

export const HashTagText = styled.span`
  border: 1px solid #000000;
  border-radius: 30px;
  padding: 6px 12px 6px 12px;
  font-family: 'PretendardSemiBold';
  font-weight: 600;
  font-size: 11px;
  line-height: 13px;

  & {
    margin-right: 6px;
    margin-top: 6px;
  }
`;

export const CommentBox = styled.div`
  box-sizing: border-box; /* 자식을 20px 줄여라 */
  min-width: 264px;
  max-width: 60px;
  text-align: left;
  font-family: 'PretendardRegular';
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  padding-top: 12px;
`;

export const CommentText = styled.span`
  display: inline-block;
  height: 60px;
  text-align: left;
  font-family: 'PretendardRegular';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #000000;
  text-overflow: ellipsis;
  overflow: hidden;
`;

export const ColorBox = styled.div`
  padding-top: 10px;
`;

export const EmojiValue = styled.div`
  width: 60px;
  height: 60px;
  font-size: 40px;
  background: ${(props: any) => (props.children ? 'transparent' : '#a46dff')};
  text-align: center;

  color: #000000;
`;

export const ColorChip = styled.div`
  display: inline-block;
  background: ${(props: any) => props.color};
  width: 25px;
  height: 25px;
  border-radius: 10px;
  & + & {
    margin-left: 10px;
  }
  br + & {
    margin-top: 4px;
  }
`;

// 트미카드 상호작용 클릭 버튼 스타일
export const InteractBtsBox = styled.div``;
export const InteractBtn = styled.span`
  padding: 10px;
  background: ${(props: any) => props.color};
  border-radius: 50px;
  width: 50px;
  height: 50px;
  align-items: center;
  font-family: 'PretendardMedium';
  font-style: normal;
  font-weight: 400;
  font-size: 30px;
  line-height: 48px;
  text-align: center;
  vertical-align: middle;

  & + & {
    margin-left: 20px;
  }
`;

export const InteractBox = styled.div`
  width: 264px;
  height: 68px;
  display: flex;
  text-align: center;
  align-items: center;
  background: #ffffff;
  border-radius: 30px;
  justify-content: center;
`;

// 상호작용 창 스타일
export const InteractCountContainer = styled.div`
  top: 130px;
  right: 55px;
  width: 96px;
  height: 130px;
  background: #ffffff;
  border-radius: 15px;
  display: inline;
  align-items: center;
  justify-content: center;
  position: absolute;
  box-sizing: border-box; /* 자식을 20px 줄여라 */
  padding-top: 20px;
  padding-left: 15px;
`;

export const InteractCountBox = styled.div`
  text-align: left;
  /* box-sizing: border-box; 자식을 20px 줄여라 */
  // overflow: auto;
  & + & {
    margin-top: 15px;
  }
`;

export const InteractCountLogo = styled.span`
  width: 20px;
  height: 20px;

  font-family: 'PretendardRegular';
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;

  color: #000000;
`;

export const InteractCountText = styled.span`
  font-family: 'PretendardRegular';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 21px;
  text-align: right;
  padding-left: 15px;
  color: #000000;
`;
