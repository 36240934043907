import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import SignIn1 from './pages/SignIn/SignIn1';
import SignIn2 from './pages/SignIn/SignIn2/signIn2';
import SignIn3 from './pages/SignIn/SignIn3/signIn3';
import SignIn4 from './pages/SignIn/SignIn4/signIn4';
import TmiBoard from './pages/TmiBoard/TmiBoard';
import NotFound from './pages/NotFound';
import KakaoLogin from './components/KakaoLogin';
import './App.css';
import UpdateTmiBoard from './pages/TmiBoard/UpdateTmiBoard/UpdateTmiBoard';
import AddTmiCardPage from './pages/AddTmiCard/AddTmiCardPage';
import TestApiPage from './pages/TestPage/TestApiPage';

function App() {
  return (
    <div className='App'>
      <BrowserRouter>
        <Routes>
          {/* <Route path='/' element={<Main />}></Route> */}
          <Route path='/' element={<SignIn1 />}></Route>
          <Route path='/signin1/*' element={<SignIn1 />}></Route>
          <Route path='/signin2/*' element={<SignIn2 />}></Route>
          <Route path='/signin3/*' element={<SignIn3 />}></Route>
          <Route path='/signin4/*' element={<SignIn4 />}></Route>
          <Route path='/tmiboard/' element={<TmiBoard />}></Route>
          <Route path='/tmiboard/:userId' element={<TmiBoard />}></Route>
          <Route path='/users/login/kakao' element={<KakaoLogin />}></Route>
          <Route path='/tmiboard/update' element={<UpdateTmiBoard />}></Route>
          {/* 트미카드 업데이트 */}
          <Route path='/tmicard/update' element={<AddTmiCardPage />}></Route>

          {/* 테스트 */}
          <Route path='/tmiboard/admin/test' element={<TestApiPage />}></Route>
          {/* 일치하는 라우트가 없는경우 처리 */}
          <Route path='*' element={<NotFound />}></Route>
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
