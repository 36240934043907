import React, { useState, useRef } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import {
  EntireBox,
  LargeBox,
  ContentsBox,
  TopBox,
  EmojiBox,
  EmojiText,
  TitleTextBox,
  TitleText,
  HashTagBox,
  CommentBox,
  CommentText,
  ButtonBox,
  ButtonIcon,
  AdminButtonImg,
  LikeButtonImg,
  LikeButtonBox,
  LikeButtonIcon,
  InteractBox,
  InteractBtn,
  InteractBtsBox,
  InteractCountBox,
  InteractCountText,
  InteractCountLogo,
  InteractCountContainer,
} from './tmiCardLarges.styled';
// import EmojiPicker, { EmojiClickData } from 'emoji-picker-react';
import HashTagContentsWhite from '../components/HashTagContentsWhite';
import imgBin from '../assets/images/btn_bin.png';
import imgHeart from '../assets/images/btn_heart.png';
import imgUpt from '../assets/images/btn_upt.png';
import imgHeartWt from '../assets/images/btn_heart_white.png';
import AdminTmiCardLargeUpdate, {
  TmiCardLargesAllProps,
} from '../components/TmiCard/AdminTmiCardLargeUpdate';

export interface ModalDataProps {
  isOpenModal: boolean;
  setOpenModal: React.Dispatch<React.SetStateAction<boolean>>;
  onClickToggleModal: any;
}

interface TmiCardLargesProps {
  data: TmiCardLargesAllProps; // 큰 카드 하나의 데이터
  array: TmiCardLargesAllProps[]; // data 전체
  setArray: React.Dispatch<React.SetStateAction<TmiCardLargesAllProps[]>>; // 전체 data 변경함수
  // ModalDataProps: ModalDataProps;
}

// 각 카드 한개에 대한 정보

const TmiCardLarges = ({
  data,
  array,
  setArray,
}: // ModalDataProps,
TmiCardLargesProps) => {
  const { userId } = useParams(); // 링크에 담긴 userId
  const [isTmiCardUptMode, setIsTmiCardUptMode] = useState<boolean>(false);
  const [isClickInteractBtn, setIsClickInteractBtn] = useState<boolean>(false); // (상대방뷰) 상호작용 이모지 출력여부
  const interactBoxRef = useRef<HTMLDivElement>(null);
  // const [isOpenModal, setOpenModal] = useState<boolean>(false);
  const [isOpenInteractView, setIsOpenInteractView] = useState<boolean>(false); // 이모지 개수 창 출력 여부
  const api = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
  });

  // const onClickToggleModal = useCallback(() => {
  //   setOpenModal(!isOpenModal);
  // }, [isOpenModal]);

  const onClickTmiCardUpt = () => {
    console.log('트미카드 수정 모드');
    setIsTmiCardUptMode(true);
  };

  // useEffect(() => {
  //   const handleClickOutside = (event: any) => {
  //     console.log('인터렉 바ㅜ낌');
  //     if (isClickInteractBtn) {
  //       // 버튼이 열려있을 때
  //       if (
  //         interactBoxRef.current &&
  //         !interactBoxRef.current.contains(event.target)
  //       ) {
  //         console.log('클릭');
  //         setIsClickInteractBtn(false);
  //       }
  //     }
  //   };

  //   document.addEventListener('mousedown', handleClickOutside);

  //   return () => {
  //     document.removeEventListener('mousedown', handleClickOutside);
  //   };
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [interactBoxRef]);

  // const onClickEmoji = (emojiData: EmojiClickData) => {
  //   setIsSelectEmoji(!isSelectEmoji);
  // };

  const onClickInteractBtn = () => {
    setIsClickInteractBtn(true);
  };

  const onClickInteract = (event: any) => {
    const btnStatusName = event.target.id;
    console.log(btnStatusName, 'id: ', data.cardId);
    console.log(localStorage.getItem('loginUserId'));

    // 상호작용 API
    api
      .post(
        `tmicard/interact`,
        {
          userId: localStorage.getItem('loginUserId'),
          cardId: data.cardId,
          likeStatus: btnStatusName,
        },
        {
          headers: {
            'X-ACCESS-TOKEN': localStorage.getItem('loginJwt'),
          },
        }
      )
      .then(function (response: any) {
        // 값 바꿔주기
        // TODO: 상호작용 여부 데이터 추가되면 수정해주기
      })
      .catch(function (error: any) {
        console.log('상호작용 에러', error);
        window.alert('상호작용에 실패하였습니다.');
      })
      .then(function () {});

    // 여기 추가
    setArray((prevArray) =>
      prevArray.map((item) => {
        if (item.cardId === data.cardId) {
          return { ...item, selectedInteract: btnStatusName };
        }
        return item;
      })
    );
  };

  const onClickDelete = () => {
    console.log('삭제', data.cardId);

    api
      .delete(
        `tmicard/delete/${data.cardId}?userId=${localStorage.getItem(
          'loginUserId'
        )}`,
        {
          headers: {
            'X-ACCESS-TOKEN': localStorage.getItem('loginJwt'),
          },
        }
      )
      .then(function (response: any) {
        const resResult = response.data.result;
        console.log(resResult);
        window.location.replace(
          `/tmiboard/${localStorage.getItem('loginUserId')}`
        );
      })
      .catch(function (error: any) {
        console.log('트미카드 삭제 실패', error);
        window.alert('트미카드 삭제 실패');
      })
      .then(function () {});
    // const id = event.target.attributes;/;
  };

  const onClickInteractView = () => {
    setIsOpenInteractView(!isOpenInteractView);
  };

  return (
    <>
      {isTmiCardUptMode ? (
        <AdminTmiCardLargeUpdate
          data={data}
          addTmiCardData={array}
          setAddTmiCardData={setArray}
          setIsTmiCardUptMode={setIsTmiCardUptMode}
        ></AdminTmiCardLargeUpdate>
      ) : (
        <EntireBox>
          <LargeBox color={data.color} id={`${data.cardId}`}>
            <ContentsBox>
              <TopBox>
                <EmojiBox>
                  <EmojiText>{data.emoji}</EmojiText>
                </EmojiBox>
                <TitleTextBox>
                  <TitleText>{data.title}</TitleText>
                </TitleTextBox>
              </TopBox>
              <HashTagBox>
                <HashTagContentsWhite
                  array={data.hashTag}
                  // TODO 이부분 수정
                ></HashTagContentsWhite>
              </HashTagBox>
              <CommentBox>
                <CommentText>{data.comments}</CommentText>
              </CommentBox>
              {/* 관리자 버튼 모드 */}
              {`${localStorage.getItem('loginUserId')}` === userId ? (
                <ButtonBox>
                  <ButtonIcon>
                    <AdminButtonImg
                      src={imgBin}
                      alt=''
                      onClick={onClickDelete}
                    ></AdminButtonImg>
                  </ButtonIcon>
                  <ButtonIcon>
                    <AdminButtonImg
                      src={imgUpt}
                      alt=''
                      onClick={onClickTmiCardUpt}
                    ></AdminButtonImg>
                  </ButtonIcon>
                  <ButtonIcon onClick={onClickInteractView}>
                    <AdminButtonImg src={imgHeart} alt=''></AdminButtonImg>
                    {/* {상호작용 개수 표 보기} */}
                    {isOpenInteractView && (
                      <InteractCountContainer>
                        <InteractCountBox>
                          <InteractCountLogo>👍🏻</InteractCountLogo>
                          <InteractCountText>
                            {String(data.greatCount).padStart(2, '0')}
                          </InteractCountText>
                        </InteractCountBox>
                        <InteractCountBox>
                          <InteractCountLogo>🥰</InteractCountLogo>
                          <InteractCountText>
                            {String(data.heartCount).padStart(2, '0')}
                          </InteractCountText>
                        </InteractCountBox>
                        <InteractCountBox>
                          <InteractCountLogo>👏🏻</InteractCountLogo>
                          <InteractCountText>
                            {String(data.clapCount).padStart(2, '0')}
                          </InteractCountText>
                        </InteractCountBox>
                      </InteractCountContainer>
                    )}
                  </ButtonIcon>
                </ButtonBox>
              ) : (
                <LikeButtonBox>
                  {/* 상호작용 버튼 모드 */}
                  {isClickInteractBtn ? (
                    <InteractBox ref={interactBoxRef}>
                      <InteractBtsBox>
                        <InteractBtn
                          id='GREAT'
                          onClick={onClickInteract}
                          color={
                            data.selectedInteract === 'GREAT'
                              ? '#000000'
                              : '#FFFFFFF'
                          }
                        >
                          👍🏻
                        </InteractBtn>
                        <InteractBtn
                          id='HEART'
                          onClick={onClickInteract}
                          color={
                            data.selectedInteract === 'HEART'
                              ? '#000000'
                              : '#FFFFFFF'
                          }
                        >
                          🥰
                        </InteractBtn>
                        <InteractBtn
                          id='CLAP'
                          onClick={onClickInteract}
                          color={
                            data.selectedInteract === 'CLAP'
                              ? '#000000'
                              : '#FFFFFFF'
                          }
                        >
                          👏🏻
                        </InteractBtn>
                      </InteractBtsBox>
                    </InteractBox>
                  ) : (
                    <LikeButtonIcon>
                      <LikeButtonImg
                        src={imgHeartWt}
                        alt=''
                        onClick={onClickInteractBtn}
                      ></LikeButtonImg>
                    </LikeButtonIcon>
                  )}
                </LikeButtonBox>
              )}
            </ContentsBox>
          </LargeBox>
        </EntireBox>
      )}
    </>
  );
};

export default TmiCardLarges;
