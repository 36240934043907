/* eslint-disable react-hooks/rules-of-hooks */
import React, { useState } from 'react';
import axios from 'axios';
import {
  HeaderBox,
  TitleSign,
  SkipButton,
  ContentBox,
  PlusImg,
  AddTmiCardBox,
  ExBigCardImg,
  ExampleContent,
} from './addTmiCardPage.styled';
import { useNavigate } from 'react-router-dom';
import AdminTmiCardLarges, {
  TmiCardLargesAllProps,
} from '../../components/TmiCard/AdminTmiCardLarges';
import exBigCard from '../../assets/images/exBigCard.png';
import { EntireBox } from './addTmiCardPage.styled';

export interface AddTmiCardProps {
  addTmiCardData: any;
  setAddTmiCardData: React.Dispatch<
    React.SetStateAction<TmiCardLargesAllProps[]>
  >;
}

// props 타입 정의
export interface MyboardInfo {
  emoji: string;
  birthStatus: string;
  name: string;
  mbti: string;
  birth: string;
  myboardComments: string;
  url1: string;
  url1Type: string;
  url2: string;
  url2Type: string;
  url3: string;
  url3Type: string;
}

const initialAddTmiCardLargesAllProps: TmiCardLargesAllProps = {
  cardId: 0,
  color: '#FDE27E',
  emoji: '🥐' || '🏋️‍♀️',
  title: '주제',
  hashTag: [],
  comments: '',
  greatCount: 0,
  heartCount: 0,
  clapCount: 0,
};

const AddTmiCardPage = () => {
  const [addTmiCardData, setAddTmiCardData] = useState<TmiCardLargesAllProps[]>(
    []
  );
  const navigate = useNavigate();
  const api = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
  });

  /* 완료 */
  const onClickFinish = async () => {
    // 트미카드 저장
    for (let i = 0; i < addTmiCardData.length; i += 1) {
      saveTmiCard(addTmiCardData[i]);
    }
  };

  /* 트미카드 저장 */
  const saveTmiCard = async (insertDate: TmiCardLargesAllProps) => {
    api
      .post(
        `tmicard/create`,
        {
          userId: localStorage.getItem('loginUserId'),
          cardEmoji: insertDate.emoji,
          cardColor: insertDate.color,
          title: insertDate.title ?? '',
          comments: insertDate.comments,
          hashTagList: insertDate.hashTag,
        },
        {
          headers: {
            'X-ACCESS-TOKEN': localStorage.getItem('loginJwt'),
          },
        }
      )
      .then(function (response: any) {
        console.log(response);
        navigate(`/tmiboard/${localStorage.getItem('loginUserId')}`);
      })
      .catch(function (error: any) {
        console.log('작성 에러', error);
        window.alert('트미카드 작성에 실패하였습니다.');
        navigate('/'); // 로그인 실패하면 로그인화면으로 돌려보냄
      })
      .then(function () {});
  };

  /* 트미카드 추가 */
  const onClickPlus = () => {
    // 현재 추가되어있는 카드의 개수 인덱스로 각각의 카드를 구분

    const newAddTmiCard = {
      ...initialAddTmiCardLargesAllProps,
      cardId: addTmiCardData.length, // 현재 배열 길이로 설정하지 않음
    };
    setAddTmiCardData([...addTmiCardData, newAddTmiCard]); // 이전 state 값을 사용하여 업데이트
    // const rnum = addTmiCardData.length;
    // console.log('개수:', rnum);
    // const currentTmiCardData = [...addTmiCardData];
    // const newAddTmiCard = initialAddTmiCardLargesAllProps;
    // newAddTmiCard.cardId = rnum;
    // setAddTmiCardData([...currentTmiCardData, newAddTmiCard]);
    /* 새로운 카드 데이터를 추가하는 로직에서 이미 존재하는 addTmiCardData 배열의 값들을 복사하여 currentTmiCardData 변수에 저장하고, 이후에 새로운 카드 데이터를 생성하여 currentTmiCardData 배열에 추가하고, 최종적으로 setAddTmiCardData 함수를 사용하여 state를 업데이트 */
  };

  return (
    <div>
      <>
        <EntireBox>
          <HeaderBox>
            <TitleSign>새로운 트미카드를 작성하세요!</TitleSign>
            <SkipButton onClick={onClickFinish}>완료</SkipButton>
          </HeaderBox>
          <ContentBox>
            <AddTmiCardBox>
              <AdminTmiCardLarges
                addTmiCardData={addTmiCardData}
                setAddTmiCardData={setAddTmiCardData}
              ></AdminTmiCardLarges>
            </AddTmiCardBox>
            <PlusImg onClick={onClickPlus} />
            <ExampleContent>
              <ExBigCardImg src={exBigCard} />
            </ExampleContent>
          </ContentBox>
        </EntireBox>
      </>
    </div>
  );
};

export default AddTmiCardPage;
