import styled from 'styled-components';
import '../assets/fonts/font.css';

export const EntireBox = styled.div`
  box-sizing: border-box;
  max-width: 393px;
  margin: auto;
`;
export const MainBox = styled.div`
  text-align: center;
  text-align: left;
  box-sizing: border-box;
  width: calc(100% - 30px);
  height: 186px;
  box-shadow: 3px 4px 15px rgba(0, 0, 0, 0.15);
  border-radius: 25px;
  background: #ffffff;
  padding-top: 42px;
`;

export const MyProfile1 = styled.div`
  display: flex;
  width: calc(100% - 40px);
  margin: auto;
  align-items: flex-end;
  justify-content: space-between;
`;

export const LeftBox = styled.div`
  flex-direction: column;
`;

export const RightBox = styled.div`
  margin-left: 20px;
`;

export const RedBox = styled.p`
  margin: 0px;
  font-family: 'GmarketSansMedium';
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: #939393;
  width: 70px;
  font-style: normal;
`;

export const GreenBox = styled.div`
  font-family: 'PretendardMedium';
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: #000000;
  text-align: left;
  height: 51px;
  padding-top: 30px;
`;

export const BlueBox = styled.div`
  width: 30px;
  height: 30px;

  background: #d9d9d9;
  border-radius: 10px;

  & + & {
    margin-top: 6px;
  }
`;

export const BlueImg = styled.img`
  width: 30px;
  height: 30px;

  ${(props) =>
    !props.src &&
    `
    opacity: 0;
    `}
`;
