import styled from 'styled-components';
import '../../../assets/fonts/font.css';

//HeaderBox Title1 Title2
export const EntireBox = styled.div`
  // background: red;
  box-sizing: border-box;
  max-width: 393px;
  margin: auto;
  // display: inline-block;
`;

//HeaderBox Title1 Title2
export const HeaderBox = styled.div`
  box-sizing: border-box;
  // background: yellow;
  padding-top: 60px;
  padding-left: 42px;
  margin-bottom: 20px;
`;

export const TitleBox = styled.div`
  // background: blue;
  width: 100%;
  text-align: left;
  padding-bottom: 40px;
`;

export const Title1 = styled.p`
  font-family: 'PretendardMedium';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #000000;
`;

export const Title2 = styled.span`
  font-family: 'PretendardMedium';
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 19px;
  color: #000000;
`;

export const ProfileBox = styled.div`
  position: relative;
  box-sizing: border-box;
`;

export const EmojiBox = styled.div`
  // padding-top: 20px;
  position: absolute;
  top: -61px;
  right: 35%;
  // width: auto;
  // height: 100px;
  font-size: 80px;
`;
export const TextName = styled.p`
  // background: pink;
  top: 38px;
  left: 40%;
  position: absolute;
  margin: 0px;
  padding: 0px;
  font-family: 'PretendardBold';
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  text-align: center;

  color: #000000;
`;

export const MyboardBox = styled.div`
  width: auto;
  text-align: -webkit-center;
  margin: 0; /* 기본 마진 제거 */
`;

export const UrlText = styled.div`
  font-family: 'PretendardMedium';
  justify-content: center;
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  color: #b1b1b1;
  margin-top: 15px;
  margin-bottom: 15px;
`;

export const SnsIconBox = styled.div`
  margin-bottom: 10px;
`;

export const SnsIcon = styled.img`
  & + & {
    margin-left: 10px;
  }
`;

export const UrlInputs = styled.div`
  width: calc(100% - 60px);
  margin: auto;
  padding: 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const UrlBox = styled.div`
  box-sizing: border-box;
  text-align: -webkit-center;
  margin: 0; /* 기본 마진 제거 */
`;

export const SnsBox = styled.div`
  // box-sizing: border-box;
`;

export const BtnBox = styled.div`
  text-align: right;
  padding-right: 27px;
  // padding-top: 20px;
`;
