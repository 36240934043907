import React from 'react';
import { EntireBox, HashTag, HashTagText } from './HashTagContentsBlack.styled';
interface HashTagProps {
  array: string[];
}

const HashTagContentsBlack = ({ array }: HashTagProps) => {
  return (
    <EntireBox>
      {array.map((tag: string, index: number) => (
        <HashTag key={index}>
          <HashTagText>{tag}</HashTagText>
        </HashTag>
      ))}
    </EntireBox>
  );
};

export default HashTagContentsBlack;
